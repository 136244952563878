import React, { useState, useEffect } from 'react'
import { Navbar, Container, Button, Spinner, Row, Col, Card, Form, FloatingLabel, Modal } from 'react-bootstrap'
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebaseConfig';

export default function EditOrganization() {

  const [show, setShow] = useState(false);
  const [modalError, setModalError] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showDialog, setShowDialog] = useState(false);

  const handleCloseDialog = () => setShowDialog(false);
  const handleShowDialog = () => setShowDialog(true);

  const [clicked, setClicked] = useState(false)

  const { checkIsExist, updateOrganizationData, deleteOrganization } = useAuth();

  const deleteHandle = async (organization) => {
    setClicked(true)
    await deleteOrganization(organization)
    setClicked(false)
    navigate("/admin-paneli/kurum-listesi")
  }

  const navigate = useNavigate()

  const handleClick = async () => {
    setClicked(true)
    if (name && adress && phone.length === 10 && username && password.length >= 6) {
      const isExist = await checkIsExist(oldData.username)
      if (isExist) {
        try {
          await updateOrganizationData(params.id, oldData, {
            name: name.trim(),
            tag: tag.trim(),
            email: `${username.trim()}@dereceyks.com`,
            adress: adress.trim(),
            phone: phone.trim(),
            username: username.trim(),
            password: password.trim(),
          })
          setClicked(false)
          navigate("/admin-paneli/kurum-listesi")
        } catch {
          setClicked(false)
          // alert("Güncelleme işlemi yapılırken bir hata meydana geldi.")
          setModalError("Güncelleme işlemi yapılırken bir hata meydana geldi.")
          handleShow()
        }
      } else {
        setClicked(false)
        // alert("Böyle bir kullanıcı yok.")
        setModalError("Böyle bir kullanıcı yok.")
        handleShow()
        navigate("/admin-paneli/kurum-listesi")
      }
    } else {
      setClicked(false)
      // alert("Lütfen tüm alanları tam ve doğru doldurun.")
      setModalError("Lütfen tüm alanları tam ve doğru doldurun.")
      handleShow()
    }
  }

  const [name, setName] = useState("")
  const [tag, setTag] = useState("")
  const [adress, setAdress] = useState("")
  const [phone, setPhone] = useState("")
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")

  const [oldData, setOldData] = useState()

  const params = useParams();

  useEffect(() => {
    const getOrganizationData = async () => {
      const uid = params.id
      const docRef = doc(db, "organizations", uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setOldData(docSnap.data())
      } else {
        navigate("/admin-paneli/kurum-listesi")
      }
    }

    getOrganizationData()
  }, [params.id, navigate])

  useEffect(() => {
    if (oldData) {
      setName(oldData.name)
      setTag(oldData.tag)
      setAdress(oldData.adress)
      setPhone(oldData.phone)
      setUsername(oldData.username)
      setPassword(oldData.password)
    }
  }, [oldData])

  return (
    <>
      <Navbar style={{ marginBottom: "24px" }} expand="lg" className="bg-white border-bottom">
        <Container>
          <Navbar.Brand>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {/* tıklanamayan */}
              <p style={{ margin: 0, color: "#000000A6", fontSize: "0.9rem", textDecoration: "none", cursor: "default" }}>Kurumlar</p>

              {/* çizgi */}
              <p style={{ margin: "0 6px", color: "#000000A6", fontSize: "0.9rem" }} >/</p>

              <a href='/admin-paneli/kurum-listesi' style={{ margin: 0, fontSize: "0.9rem", fontWeight: "400", color: "#000000A6", textDecoration: "none" }} >Kurum Listesi</a>

              <p style={{ margin: "0 6px", color: "#000000A6", fontSize: "0.9rem" }} >/</p>

              <p style={{ margin: 0, color: "#000000", fontSize: "0.9rem", textDecoration: "none", cursor: "default", fontWeight: "500" }}>Kurum Düzenle</p>

              {/* tıklanabilir */}
              {/* <a href='/kurum-paneli/ogrenci-listesi/ogrenci-ekle' style={{ margin: 0, fontSize: "1rem", fontWeight: "500", color: "#000000", textDecoration: "none" }} >Öğrenci Ekle</a> */}
            </div>
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Container style={{ marginBottom: "24px" }}>
        {
          oldData && <Container style={{ display: "flex", justifyContent: "center", alignItems: "flex-start" }}>
            <Row style={{ width: "100%", display: "flex", justifyContent: "center" }}>
              <Col lg={3} md={2} sm={1} />
              <Col lg={6} md={8} sm={10} style={{ padding: "0", maxWidth: "450px" }}>
                <Card >
                  <Card.Body>
                    <Form onSubmit={e => { e.preventDefault(); }}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <FloatingLabel
                          controlId="floatingInput1"
                          label="Kurum"
                          className="mb-3"
                        >
                          <Form.Control disabled type='text' placeholder="Kurum" value={name} onChange={(e) => setName(e.target.value)} />
                        </FloatingLabel>
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <FloatingLabel
                          controlId="floatingInput1"
                          label="Kısaltma"
                          className="mb-3"
                        >
                          <Form.Control disabled type='text' placeholder="Kısaltma" value={tag} onChange={(e) => setTag(e.target.value.trim().toUpperCase())} />
                        </FloatingLabel>
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <FloatingLabel
                          controlId="floatingInput2"
                          label="Adres"
                          className="mb-3"
                        >
                          <Form.Control type='text' placeholder="Adres" value={adress} onChange={(e) => setAdress(e.target.value)} />
                        </FloatingLabel>
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <FloatingLabel
                          controlId="floatingInput3"
                          label="Telefon"
                          className="mb-3"
                        >
                          <Form.Control type='text' placeholder="Telefon" value={phone} onChange={(e) => setPhone(e.target.value.trim())} maxLength={10} />
                        </FloatingLabel>
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <FloatingLabel
                          controlId="floatingInput4"
                          label="Kullanıcı Adı"
                          className="mb-3"
                        >
                          <Form.Control type='text' placeholder="Kullanıcı Adı" value={username} onChange={(e) => setUsername(e.target.value.trim())} />
                        </FloatingLabel>
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formBasicPassword">
                        <FloatingLabel controlId="floatingPassword" label="Parola">
                          <Form.Control type="text" placeholder="Parola" autoComplete='new-password' value={password} onChange={(e) => setPassword(e.target.value.trim())} />
                        </FloatingLabel>
                      </Form.Group>

                      {
                        clicked
                          ? <Button style={{ width: "100%" }} variant="outline-secondary" disabled>
                            <Spinner
                              style={{ marginRight: "6px" }}
                              as="span"
                              size="sm"
                              aria-hidden="true"
                            />
                          </Button>
                          : <>
                            <Button onClick={handleClick} style={{ width: "100%" }} variant="primary" >
                              Kaydet
                            </Button>
                            <Button onClick={() => handleShowDialog()} disabled={clicked || !oldData ? true : false} style={{ marginTop: "12px", width: "100%" }} variant="outline-danger" >
                              Kurumu Sil
                            </Button>
                          </>
                      }

                    </Form>
                  </Card.Body>
                </Card>

              </Col>
              <Col lg={3} md={2} sm={1} />
            </Row>
          </Container>
        }

        <Modal
          show={show}
          onHide={handleClose}
          keyboard={false}
        >
          <Modal.Header closeButton>
            {/* <Modal.Title>{modalTitle}</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            {modalError}
          </Modal.Body>

        </Modal>

        <Modal
          show={showDialog}
          onHide={handleCloseDialog}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
            Geri dönüşü olmayan bir işlem yapmak üzeresiniz. Kurumu tüm verileri ile birlikte silmek istediğinizden emin misiniz?
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => {
              deleteHandle(oldData)
              handleCloseDialog()
            }} variant="danger">Kurumu Sil</Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  )
}
