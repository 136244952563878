import React, { useEffect, useState } from 'react'
import { Navbar, Container, Button, Spinner, Row, Col, Card, Form, FloatingLabel, Modal, Accordion } from 'react-bootstrap'
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../firebaseConfig';

export default function EditTeacher() {

  const [show, setShow] = useState(false);
  const [modalError, setModalError] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showDialog, setShowDialog] = useState(false);

  const handleCloseDialog = () => setShowDialog(false);
  const handleShowDialog = () => setShowDialog(true);

  const branchNames = ["Rehberlik", "Türkçe", "Edebiyat", "Tarih", "Coğrafya", "Felsefe", "Din Kültürü ve Ahlak Bilgisi", "Matematik", "Geometri", "Fizik", "Kimya", "Biyoloji", "İngilizce", "Almanca", "Fransızca", "Arapça", "Rusça"]

  const [clicked, setClicked] = useState(false)

  const { userData, updateTeacherData, deleteTeacher } = useAuth();

  const deleteHandle = async (teacher) => {
    setClicked(true)
    await deleteTeacher(teacher)
    setClicked(false)
    navigate("/kurum-paneli/ogretmen-listesi")
  }

  const navigate = useNavigate()

  const params = useParams();

  const [oldData, setOldData] = useState()

  useEffect(() => {
    const getTeacherData = async () => {
      const uid = params.id
      const docRef = doc(db, "teachers", uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const dataForTeacher = docSnap.data()
        if (dataForTeacher.organizationId === userData.uid) {
          setOldData(docSnap.data())
        }
        else {
          navigate("/kurum-paneli/ogretmen-listesi")
        }
      } else {
        navigate("/kurum-paneli/ogretmen-listesi")
      }
    }
    getTeacherData()
  }, [params.id, navigate, userData])

  const handleClick = async () => {
    setClicked(true)
    if (userData && branches.length > 0 && oldData) {
      try {
        await updateTeacherData(params.id, {
          branches: branches,
          students: students || [],
        }, oldData.students)
        setClicked(false)
        navigate("/kurum-paneli/ogretmen-listesi")
      } catch {
        setClicked(false)
        // alert("Bir sorun oluştu. Lütfen tekrar deneyin.")
        setModalError("Bir sorun oluştu. Lütfen tekrar deneyin.")
        handleShow()
      }
    } else {
      setClicked(false)
      // alert("Lütfen tüm alanları tam ve doğru doldurun.")
      setModalError("Lütfen tüm alanları tam ve doğru doldurun.")
      handleShow()
    }
  }

  const [name, setName] = useState("")
  const [surname, setSurname] = useState("")
  const [branches, setBranches] = useState([])
  const [students, setStudents] = useState([])

  const [allStudents, setAllStudents] = useState()

  useEffect(() => {
    if (oldData) {
      // console.log("OLD DATA TEACHER:", oldData);
      setName(oldData.name)
      setSurname(oldData.surname)
      setBranches(oldData.branches)
      setStudents(oldData.students)
    }
  }, [oldData])

  const handleBranches = async (value) => {
    const isExist = branches.includes(value)
    if (isExist) {
      if (branches.length > 1) {
        try {
          const index = branches.indexOf(value)
          const copy = Array.from(branches)
          copy.splice(index, 1)
          setBranches(copy)
        } catch (error) {
          console.log(error);
        }

      } else {
        setBranches([])
      }
    } else {
      setBranches(prev => [...prev, value])
    }
  }

  const handleStudents = async (value) => {
    const isExist = students.includes(value)
    if (isExist) {
      if (students.length > 1) {
        try {
          const index = students.indexOf(value)
          const copy = Array.from(students)
          copy.splice(index, 1)
          setStudents(copy)
        } catch (error) {
          console.log(error);
        }

      } else {
        setStudents([])
      }
    } else {
      setStudents(prev => [...prev, value])
    }
  }

  // useEffect(() => {
  //   if (students && branches) {
  //     console.log("STUDENTS:", students);
  //     console.log("BRANCHES:", branches);
  //   }
  // }, [students, branches])

  useEffect(() => {
    if (userData) {
      const getStudents = async () => {
        const students = [];
        const querySnapshot = await getDocs(query(collection(db, "students"), where("organizationId", "==", userData.uid)));
        querySnapshot.forEach((doc) => {
          students.push(doc.data())
        });
        students.sort((x, y) => {
          return y.createdAt - x.createdAt
        })
        setAllStudents(students)
      }

      getStudents()
    }
  }, [userData])

  const handleQuickActions = async (actionType) => {
    if (actionType === "SELECT-ALL-STUDENTS") {
      const temp = []
      await allStudents.forEach((student) => {
        temp.push(student.uid)
      })
      setStudents(temp)
    }
    else if (actionType === "REMOVE-ALL-STUDENTS") {
      setStudents([])
    }
    else if (actionType === "SELECT-ALL-9-GRADE") {
      const temp2 = [...students]
      await allStudents.forEach((student) => {
        if (student.grade === "9") {
          if (!temp2.includes(student.uid)) {
            temp2.push(student.uid)
          }
        }
      })
      setStudents(temp2)
    }
    else if (actionType === "REMOVE-ALL-9-GRADE") {
      const temp3 = [...students]
      const willClear = []

      temp3.forEach((studentId) => {
        const finded = allStudents.find((student) => student.uid === studentId)
        if (finded.grade === "9") willClear.push(finded.uid)
      })

      const filtered = temp3.filter((studentId) => !willClear.includes(studentId))
      setStudents(filtered)
    }
    else if (actionType === "SELECT-ALL-10-GRADE") {
      const temp2 = [...students]
      await allStudents.forEach((student) => {
        if (student.grade === "10") {
          if (!temp2.includes(student.uid)) {
            temp2.push(student.uid)
          }
        }
      })
      setStudents(temp2)
    }
    else if (actionType === "REMOVE-ALL-10-GRADE") {
      const temp3 = [...students]
      const willClear = []

      temp3.forEach((studentId) => {
        const finded = allStudents.find((student) => student.uid === studentId)
        if (finded.grade === "10") willClear.push(finded.uid)
      })

      const filtered = temp3.filter((studentId) => !willClear.includes(studentId))
      setStudents(filtered)
    }
    else if (actionType === "SELECT-ALL-11-GRADE") {
      const temp2 = [...students]
      await allStudents.forEach((student) => {
        if (student.grade === "11") {
          if (!temp2.includes(student.uid)) {
            temp2.push(student.uid)
          }
        }
      })
      setStudents(temp2)
    }
    else if (actionType === "REMOVE-ALL-11-GRADE") {
      const temp3 = [...students]
      const willClear = []

      temp3.forEach((studentId) => {
        const finded = allStudents.find((student) => student.uid === studentId)
        if (finded.grade === "11") willClear.push(finded.uid)
      })

      const filtered = temp3.filter((studentId) => !willClear.includes(studentId))
      setStudents(filtered)
    }
    else if (actionType === "SELECT-ALL-12-GRADE") {
      const temp2 = [...students]
      await allStudents.forEach((student) => {
        if (student.grade === "12") {
          if (!temp2.includes(student.uid)) {
            temp2.push(student.uid)
          }
        }
      })
      setStudents(temp2)
    }
    else if (actionType === "REMOVE-ALL-12-GRADE") {
      const temp3 = [...students]
      const willClear = []

      temp3.forEach((studentId) => {
        const finded = allStudents.find((student) => student.uid === studentId)
        if (finded.grade === "12") willClear.push(finded.uid)
      })

      const filtered = temp3.filter((studentId) => !willClear.includes(studentId))
      setStudents(filtered)
    }
    else if (actionType === "SELECT-ALL-MEZUN-GRADE") {
      const temp2 = [...students]
      await allStudents.forEach((student) => {
        if (student.grade === "Mezun") {
          if (!temp2.includes(student.uid)) {
            temp2.push(student.uid)
          }
        }
      })
      setStudents(temp2)
    }
    else if (actionType === "REMOVE-ALL-MEZUN-GRADE") {
      const temp3 = [...students]
      const willClear = []

      temp3.forEach((studentId) => {
        const finded = allStudents.find((student) => student.uid === studentId)
        if (finded.grade === "Mezun") willClear.push(finded.uid)
      })

      const filtered = temp3.filter((studentId) => !willClear.includes(studentId))
      setStudents(filtered)
    }
    else if (actionType === "SELECT-ALL-SAY-DOMAIN") {
      const temp2 = [...students]
      await allStudents.forEach((student) => {
        if (student.domain === "SAY") {
          if (!temp2.includes(student.uid)) {
            temp2.push(student.uid)
          }
        }
      })
      setStudents(temp2)
    }
    else if (actionType === "REMOVE-ALL-SAY-DOMAIN") {
      const temp3 = [...students]
      const willClear = []

      temp3.forEach((studentId) => {
        const finded = allStudents.find((student) => student.uid === studentId)
        if (finded.domain === "SAY") willClear.push(finded.uid)
      })

      const filtered = temp3.filter((studentId) => !willClear.includes(studentId))
      setStudents(filtered)
    }


    else if (actionType === "SELECT-ALL-EA-DOMAIN") {
      const temp2 = [...students]
      await allStudents.forEach((student) => {
        if (student.domain === "EA") {
          if (!temp2.includes(student.uid)) {
            temp2.push(student.uid)
          }
        }
      })
      setStudents(temp2)
    }
    else if (actionType === "REMOVE-ALL-EA-DOMAIN") {
      const temp3 = [...students]
      const willClear = []

      temp3.forEach((studentId) => {
        const finded = allStudents.find((student) => student.uid === studentId)
        if (finded.domain === "EA") willClear.push(finded.uid)
      })

      const filtered = temp3.filter((studentId) => !willClear.includes(studentId))
      setStudents(filtered)
    }


    else if (actionType === "SELECT-ALL-SOZ-DOMAIN") {
      const temp2 = [...students]
      await allStudents.forEach((student) => {
        if (student.domain === "SÖZ") {
          if (!temp2.includes(student.uid)) {
            temp2.push(student.uid)
          }
        }
      })
      setStudents(temp2)
    }
    else if (actionType === "REMOVE-ALL-SOZ-DOMAIN") {
      const temp3 = [...students]
      const willClear = []

      temp3.forEach((studentId) => {
        const finded = allStudents.find((student) => student.uid === studentId)
        if (finded.domain === "SÖZ") willClear.push(finded.uid)
      })

      const filtered = temp3.filter((studentId) => !willClear.includes(studentId))
      setStudents(filtered)
    }

    else if (actionType === "SELECT-ALL-DIL-DOMAIN") {
      const temp2 = [...students]
      await allStudents.forEach((student) => {
        if (student.domain === "DİL") {
          if (!temp2.includes(student.uid)) {
            temp2.push(student.uid)
          }
        }
      })
      setStudents(temp2)
    }
    else if (actionType === "REMOVE-ALL-DIL-DOMAIN") {
      const temp3 = [...students]
      const willClear = []

      temp3.forEach((studentId) => {
        const finded = allStudents.find((student) => student.uid === studentId)
        if (finded.domain === "DİL") willClear.push(finded.uid)
      })

      const filtered = temp3.filter((studentId) => !willClear.includes(studentId))
      setStudents(filtered)
    }

    else if (actionType === "SELECT-ALL-DIL-DOMAIN-ING-LANG") {
      const temp2 = [...students]
      await allStudents.forEach((student) => {
        if (student.domain === "DİL" && student.lang === "İngilizce") {
          if (!temp2.includes(student.uid)) {
            temp2.push(student.uid)
          }
        }
      })
      setStudents(temp2)
    }
    else if (actionType === "REMOVE-ALL-DIL-DOMAIN-ING-LANG") {
      const temp3 = [...students]
      const willClear = []

      temp3.forEach((studentId) => {
        const finded = allStudents.find((student) => student.uid === studentId)
        if (finded.domain === "DİL" && finded.lang === "İngilizce") willClear.push(finded.uid)
      })

      const filtered = temp3.filter((studentId) => !willClear.includes(studentId))
      setStudents(filtered)
    }


    else if (actionType === "SELECT-ALL-DIL-DOMAIN-ALM-LANG") {
      const temp2 = [...students]
      await allStudents.forEach((student) => {
        if (student.domain === "DİL" && student.lang === "Almanca") {
          if (!temp2.includes(student.uid)) {
            temp2.push(student.uid)
          }
        }
      })
      setStudents(temp2)
    }
    else if (actionType === "REMOVE-ALL-DIL-DOMAIN-ALM-LANG") {
      const temp3 = [...students]
      const willClear = []

      temp3.forEach((studentId) => {
        const finded = allStudents.find((student) => student.uid === studentId)
        if (finded.domain === "DİL" && finded.lang === "Almanca") willClear.push(finded.uid)
      })

      const filtered = temp3.filter((studentId) => !willClear.includes(studentId))
      setStudents(filtered)
    }
  }

  return (
    <>
      <Navbar style={{ marginBottom: "24px" }} expand="lg" className="bg-white border-bottom">
        <Container>
          <Navbar.Brand>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {/* tıklanamayan */}
              <p style={{ margin: 0, color: "#000000A6", fontSize: "0.9rem", textDecoration: "none", cursor: "default" }}>Öğretmenler</p>

              {/* çizgi */}
              <p style={{ margin: "0 6px", color: "#000000A6", fontSize: "0.9rem" }} >/</p>

              <a href='/kurum-paneli/ogretmen-listesi' style={{ margin: 0, fontSize: "0.9rem", fontWeight: "400", color: "#000000A6", textDecoration: "none" }} >Öğretmen Listesi</a>

              <p style={{ margin: "0 6px", color: "#000000A6", fontSize: "0.9rem" }} >/</p>

              <p style={{ margin: 0, color: "#000000", fontSize: "0.9rem", textDecoration: "none", cursor: "default", fontWeight: "500" }}>Öğretmen Düzenle</p>

              {/* tıklanabilir */}
              {/* <a href='/kurum-paneli/ogrenci-listesi/ogrenci-ekle' style={{ margin: 0, fontSize: "1rem", fontWeight: "500", color: "#000000", textDecoration: "none" }} >Öğrenci Ekle</a> */}
            </div>
          </Navbar.Brand>
        </Container>
      </Navbar>
      {
        allStudents && <Container style={{ marginBottom: "24px" }}>
          <Container style={{ display: "flex", justifyContent: "center", alignItems: "flex-start" }}>
            <Row style={{ width: "100%", display: "flex", justifyContent: "center" }}>
              <Col lg={3} md={2} sm={1} />
              <Col lg={6} md={8} sm={10} style={{ padding: "0", maxWidth: "450px" }}>
                <Card >
                  <Card.Body>
                    <Form onSubmit={e => { e.preventDefault(); }}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <FloatingLabel
                          controlId="floatingInput1"
                          label="Ad"
                          className="mb-3"
                        >
                          <Form.Control disabled type='text' placeholder="Ad" value={name} onChange={(e) => setName(e.target.value)} />
                        </FloatingLabel>
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <FloatingLabel
                          controlId="floatingInput1"
                          label="Soyad"
                          className="mb-3"
                        >
                          <Form.Control disabled type='text' placeholder="Soyad" value={surname} onChange={(e) => setSurname(e.target.value.trim())} />
                        </FloatingLabel>
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label style={{ pointerEvents: "none", fontWeight: "500" }}>Branşlar ({branches.length}) *</Form.Label>
                        <Form.Switch style={{ padding: "0 6px" }} className="mb-3" aria-label="Default select example">
                          {
                            branchNames.map((branch, index) => (
                              <Form.Check
                                checked={branches.includes(branch)}
                                disabled={clicked}
                                key={index}
                                value={branch}
                                type="switch"
                                label={branch}
                                style={{ fontSize: "1rem" }}
                                onChange={(e) => e.target.value && handleBranches(e.target.value)}
                              />
                            ))
                          }
                        </Form.Switch>
                      </Form.Group>

                      {
                        branches.length > 0 &&
                        <div style={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", marginBottom: "18px", padding: "0 8px" }}>
                          <p style={{ margin: "0 4px 0 0", fontSize: "0.9rem", fontWeight: "500", textDecoration: "underline" }}>Seçilenler:</p>
                          {
                            branches.map((branch, index) => (
                              <p key={index} style={{ margin: "0 4px 0 0", fontSize: "0.9rem" }}>{branch}{branches[index + 1] ? "," : null}</p>
                            ))
                          }
                        </div>
                      }

                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label style={{ pointerEvents: "none", fontWeight: "500" }}>Takip Edeceği Öğrenciler ({students.length})</Form.Label>
                        <Accordion defaultActiveKey="0" style={{ marginBottom: "16px" }}>
                          <Accordion.Item eventKey="1">
                            <Accordion.Header>Öğrenci Ekle-Çıkar Hızlı Aksiyonlar</Accordion.Header>
                            <Accordion.Body>
                              <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between", alignItems: "center", gap: "8px", }}>


                                <Button onClick={() => handleQuickActions("SELECT-ALL-STUDENTS")} disabled={clicked} variant="outline-primary" size='sm' style={{ flex: 1, flexBasis: "45%" }} >
                                  Tüm Öğrencileri Seç
                                </Button>
                                <Button onClick={() => handleQuickActions("REMOVE-ALL-STUDENTS")} disabled={clicked} variant="outline-danger" size='sm' style={{ flex: 1, flexBasis: "45%" }} >
                                  Tüm Öğrencileri Çıkar
                                </Button>

                                <Button onClick={() => handleQuickActions("SELECT-ALL-9-GRADE")} disabled={clicked} variant="outline-primary" size='sm' style={{ flex: 1, flexBasis: "45%" }} >
                                  9. Sınıfları Seç
                                </Button>
                                <Button onClick={() => handleQuickActions("REMOVE-ALL-9-GRADE")} disabled={clicked} variant="outline-danger" size='sm' style={{ flex: 1, flexBasis: "45%" }} >
                                  9. Sınıfları Çıkar
                                </Button>

                                <Button onClick={() => handleQuickActions("SELECT-ALL-10-GRADE")} disabled={clicked} variant="outline-primary" size='sm' style={{ flex: 1, flexBasis: "45%" }} >
                                  10. Sınıfları Seç
                                </Button>
                                <Button onClick={() => handleQuickActions("REMOVE-ALL-10-GRADE")} disabled={clicked} variant="outline-danger" size='sm' style={{ flex: 1, flexBasis: "45%" }} >
                                  10. Sınıfları Çıkar
                                </Button>


                                <Button onClick={() => handleQuickActions("SELECT-ALL-11-GRADE")} disabled={clicked} variant="outline-primary" size='sm' style={{ flex: 1, flexBasis: "45%" }} >
                                  11. Sınıfları Seç
                                </Button>
                                <Button onClick={() => handleQuickActions("REMOVE-ALL-11-GRADE")} disabled={clicked} variant="outline-danger" size='sm' style={{ flex: 1, flexBasis: "45%" }} >
                                  11. Sınıfları Çıkar
                                </Button>


                                <Button onClick={() => handleQuickActions("SELECT-ALL-12-GRADE")} disabled={clicked} variant="outline-primary" size='sm' style={{ flex: 1, flexBasis: "45%" }} >
                                  12. Sınıfları Seç
                                </Button>
                                <Button onClick={() => handleQuickActions("REMOVE-ALL-12-GRADE")} disabled={clicked} variant="outline-danger" size='sm' style={{ flex: 1, flexBasis: "45%" }} >
                                  12. Sınıfları Çıkar
                                </Button>


                                <Button onClick={() => handleQuickActions("SELECT-ALL-MEZUN-GRADE")} disabled={clicked} variant="outline-primary" size='sm' style={{ flex: 1, flexBasis: "45%" }} >
                                  Mezunları Seç
                                </Button>

                                <Button onClick={() => handleQuickActions("REMOVE-ALL-MEZUN-GRADE")} disabled={clicked} variant="outline-danger" size='sm' style={{ flex: 1, flexBasis: "45%" }} >
                                  Mezunları Çıkar
                                </Button>


                                <Button onClick={() => handleQuickActions("SELECT-ALL-SAY-DOMAIN")} disabled={clicked} size='sm' style={{ flex: 1, flexBasis: "45%" }} variant="outline-primary" >
                                  Sayısalcıları Seç
                                </Button>
                                <Button onClick={() => handleQuickActions("REMOVE-ALL-SAY-DOMAIN")} disabled={clicked} size='sm' style={{ flex: 1, flexBasis: "45%" }} variant="outline-danger" >
                                  Sayısalcıları Çıkar
                                </Button>


                                <Button onClick={() => handleQuickActions("SELECT-ALL-EA-DOMAIN")} disabled={clicked} size='sm' style={{ flex: 1, flexBasis: "45%" }} variant="outline-primary" >
                                  Eşit Ağırlıkçıları Seç
                                </Button>
                                <Button onClick={() => handleQuickActions("REMOVE-ALL-EA-DOMAIN")} disabled={clicked} size='sm' style={{ flex: 1, flexBasis: "45%" }} variant="outline-danger" >
                                  Eşit Ağırlıkçıları Çıkar
                                </Button>


                                <Button onClick={() => handleQuickActions("SELECT-ALL-SOZ-DOMAIN")} disabled={clicked} size='sm' style={{ flex: 1, flexBasis: "45%" }} variant="outline-primary" >
                                  Sözelcileri Seç
                                </Button>
                                <Button onClick={() => handleQuickActions("REMOVE-ALL-SOZ-DOMAIN")} disabled={clicked} size='sm' style={{ flex: 1, flexBasis: "45%" }} variant="outline-danger" >
                                  Sözelcileri Çıkar
                                </Button>


                                <Button onClick={() => handleQuickActions("SELECT-ALL-DIL-DOMAIN")} disabled={clicked} size='sm' style={{ flex: 1, flexBasis: "45%" }} variant="outline-primary" >
                                  Dilcileri Seç
                                </Button>
                                <Button onClick={() => handleQuickActions("REMOVE-ALL-DIL-DOMAIN")} disabled={clicked} size='sm' style={{ flex: 1, flexBasis: "45%" }} variant="outline-danger" >
                                  Dilcileri Çıkar
                                </Button>


                                <Button onClick={() => handleQuickActions("SELECT-ALL-DIL-DOMAIN-ING-LANG")} disabled={clicked} size='sm' style={{ flex: 1, flexBasis: "45%" }} variant="outline-primary" >
                                  İngilizce Dilcileri Seç
                                </Button>
                                <Button onClick={() => handleQuickActions("REMOVE-ALL-DIL-DOMAIN-ING-LANG")} disabled={clicked} size='sm' style={{ flex: 1, flexBasis: "45%" }} variant="outline-danger" >
                                  İngilizce Dilcileri Çıkar
                                </Button>


                                <Button onClick={() => handleQuickActions("SELECT-ALL-DIL-DOMAIN-ALM-LANG")} disabled={clicked} size='sm' style={{ flex: 1, flexBasis: "45%" }} variant="outline-primary" >
                                  Almanca Dilcileri Seç
                                </Button>
                                <Button onClick={() => handleQuickActions("REMOVE-ALL-DIL-DOMAIN-ALM-LANG")} disabled={clicked} size='sm' style={{ flex: 1, flexBasis: "45%" }} variant="outline-danger" >
                                  Almanca Dilcileri Çıkar
                                </Button>

                               

                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>

                        <Form.Switch style={{ padding: "0 6px" }} className="mb-3" aria-label="Default select example">
                          {
                            allStudents.map((student, index) => {
                              let label = `${student.name} ${student.surname} - ${student.grade}`
                              if (student.domain) {
                                label += ` - ${student.domain}`
                              }
                              if (student.lang) {
                                label += ` - ${student.lang}`
                              }

                              return (
                                <Form.Check
                                  checked={students.includes(student.uid)}
                                  disabled={clicked}
                                  key={index}
                                  value={student.uid}
                                  type="switch"
                                  label={label}
                                  style={{ fontSize: "1rem" }}
                                  onChange={(e) => e.target.value && handleStudents(e.target.value)}
                                />
                              )
                            })
                          }
                        </Form.Switch>
                      </Form.Group>

                      {
                        students.length > 0 &&
                        <div style={{ width: "100%", display: "flex", flexDirection: "column", flexWrap: "wrap", marginBottom: "18px", padding: "0 8px" }}>
                          <p style={{ margin: "0", fontSize: "0.9rem", fontWeight: "500", textDecoration: "underline" }}>Seçilenler:</p>
                          {
                            students.map((uid, index) => {
                              const student = allStudents.find((item) => item.uid === uid)
                              return (
                                <p key={index} style={{ margin: "0", fontSize: "0.9rem" }}>{index + 1}. {student.name} {student.surname} - {student.grade} {student.domain && `- ${student.domain}`} {student.lang && `- ${student.lang}`}</p>
                              )
                            })
                          }
                        </div>
                      }


                      <div style={{ margin: "0 0 12px 0", display: "flex", flexDirection: "row", justifyContent: "center", width: "100%", padding: 0 }}>
                        <Form.Text style={{ marginTop: 0 }} className="text-muted">
                          Yıldız (*) işareti bulunan alanları doldurmak zorunludur.
                        </Form.Text>
                      </div>


                      {
                        clicked
                          ? <Button style={{ width: "100%" }} variant="outline-secondary" disabled>
                            <Spinner
                              style={{ marginRight: "6px" }}
                              as="span"
                              size="sm"
                              aria-hidden="true"
                            />
                          </Button>
                          : <>
                            <Button onClick={handleClick} style={{ width: "100%" }} variant="primary" >
                              Kaydet
                            </Button>
                            <Button onClick={() => handleShowDialog()} disabled={clicked || !oldData ? true : false} style={{ marginTop: "12px", width: "100%" }} variant="outline-danger" >
                              Öğretmeni Sil
                            </Button>
                          </>
                      }

                    </Form>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={3} md={2} sm={1} />
            </Row>
          </Container>

          <Modal
            show={show}
            onHide={handleClose}
            keyboard={false}
          >
            <Modal.Header closeButton>
              {/* <Modal.Title>{modalTitle}</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              {modalError}
            </Modal.Body>

          </Modal>

          <Modal
            show={showDialog}
            onHide={handleCloseDialog}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
              Geri dönüşü olmayan bir işlem yapmak üzeresiniz. Öğretmeni tüm verileri ile birlikte silmek istediğinizden emin misiniz?
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => {
                deleteHandle(oldData)
                handleCloseDialog()
              }} variant="danger">Öğretmeni Sil</Button>
            </Modal.Footer>
          </Modal>
        </Container>
      }
    </>
  )
}