import { collection, getDocs, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { Navbar, Container, Table, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { db } from '../../firebaseConfig';
import { useAuth } from '../../contexts/AuthContext';

export default function UnsolvableQuestionsForTeacher() {

  const navigate = useNavigate();

  const [students, setStudents] = useState([])

  const { userData } = useAuth();

  useEffect(() => {
    if (userData) {
      const getStudents = async () => {
        const students = [];
        const querySnapshot = await getDocs(query(collection(db, "students"), where("organizationId", "==", userData.organizationId), where("teachers", "array-contains", userData.uid)));
        querySnapshot.forEach((doc) => {
          students.push(doc.data())
        });
        students.sort((x, y) => {
          return y.createdAt - x.createdAt
        })
        setStudents(students)
      }

      getStudents()
    }
  }, [userData])

  const [questions, setQuestions] = useState([])

  const getQuestions = async () => {
    const questions = [];
    const querySnapshot = await getDocs(query(collection(db, "questions"), where("organizationId", "==", userData.organizationId)));
    querySnapshot.forEach((doc) => {
      questions.push(doc.data())
    });
    let filtered = []
    questions.forEach((question) => {
      question.branches.forEach((branch1) => {
        userData.branches.forEach((branch2) => {
          if ((branch1 === branch2) && !filtered.includes(question)) {
            filtered.push(question)
          }
        })
      })
    })
    filtered.sort((x, y) => {
      return y.createdAt - x.createdAt
    })
    setQuestions(filtered)
  }

  useEffect(() => {
    if (userData) {
      getQuestions()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData])

  return (
    <>
      <Navbar style={{ marginBottom: "24px" }} expand="lg" className="bg-white border-bottom">
        <Container>
          <Navbar.Brand>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {/* tıklanamayan */}
              <p style={{ margin: 0, color: "#000000A6", fontSize: "0.9rem", textDecoration: "none", cursor: "default" }}>Öğrenciler</p>

              {/* çizgi */}
              <p style={{ margin: "0 6px", color: "#000000A6", fontSize: "0.9rem" }} >/</p>

              <p style={{ margin: 0, color: "#000000", fontSize: "0.9rem", textDecoration: "none", cursor: "default", fontWeight: "500" }}>Çözülemeyen Sorular</p>

              {/* tıklanabilir */}
              {/* <a href='/kurum-paneli/ogrenci-listesi/ogrenci-ekle' style={{ margin: 0, fontSize: "1rem", fontWeight: "500", color: "#000000", textDecoration: "none" }} >Öğrenci Ekle</a> */}
            </div>
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Container style={{ marginBottom: "24px" }}>
        {/* <Table  striped bordered responsive>
          <thead>
            <tr>
              <th style={{ width: "8.77%" }}>Toplam</th>
              <th style={{ width: "8.77%" }}>9. Sınıf</th>
              <th style={{ width: "8.77%" }}>10. Sınıf</th>
              <th style={{ width: "8.77%" }}>11. Sınıf</th>
              <th style={{ width: "8.77%" }}>12. Sınıf</th>
              <th style={{ width: "8.77%" }}>Mezun</th>
              <th style={{ width: "8.77%" }}>SAY</th>
              <th style={{ width: "8.77%" }}>EA</th>
              <th style={{ width: "8.77%" }}>SÖZ</th>
              <th style={{ width: "8.77%" }}>DİL</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr style={{ verticalAlign: "middle" }}>
              <td>{students ? students.length : 0}</td>
              <td>{nineGrade}</td>
              <td>{tenGrade}</td>
              <td>{elevenGrade}</td>
              <td>{twelveGrade}</td>
              <td>{gradGrade}</td>
              <td>{sayCount}</td>
              <td>{eaCount}</td>
              <td>{sozCount}</td>
              <td>{dilCount}</td>
              <td style={{ textAlign: "center" }}>
                <Button onClick={() => navigate("ogrenci-ekle")} size="sm" >Öğrenci Ekle</Button>
              </td>
            </tr>

          </tbody>
        </Table> */}

        {
          students
            ? students.length > 0
              ? <Table style={{ marginTop: '8px' }} striped bordered responsive>
                <thead>
                  <tr>
                    <th style={{ width: "1%" }}>#</th>
                    <th style={{ width: "20%" }}>Ad</th>
                    <th style={{ width: "20%" }}>Soyad</th>
                    <th style={{ width: "12%" }}>Öğrenci No</th>
                    <th style={{ width: "11.5%" }}>Sınıf & Şube</th>
                    <th style={{ width: "11.5%" }}>Alan</th>
                    <th style={{ width: "10%" }}>Bekleyen Soru</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    students && students.map((student, index) => {

                      const questionsFromThisStudent = questions.filter((question) => question.studentId === student.uid)

                      let pendingCount = 0

                      questionsFromThisStudent.forEach((question) => {
                        if (question.status === "pending") {
                          pendingCount += 1
                        }
                      })

                      return (
                        <tr key={index} style={{ verticalAlign: "middle" }}>
                          <td>{index + 1}</td>
                          <td>{student.name}</td>
                          <td>{student.surname}</td>
                          <td>{student.studentNo}</td>
                          <td>{student.grade} {student.classCode ? `- ${student.classCode}` : null}</td>
                          <td>{student.domain} {student.lang ? `(${student.lang[0].toUpperCase()}${student.lang[1].toUpperCase()}${student.lang[2].toUpperCase()})` : ""}</td>
                          <td>{pendingCount || 0}</td>
                          <td style={{ textAlign: "center" }}>
                            <Button onClick={() => navigate(student.uid)} size="sm">Seç</Button>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </Table>
              : null
            : null
        }


      </Container>
    </>
  )
}
