import React from 'react'
import { Button } from 'react-bootstrap';

export default function YDTExamsForStudent({ exam, index, handleShow }) {

  const date = new Date(exam.createdAt).toLocaleDateString("tr",{ day: "2-digit", month: "2-digit", year: "numeric" })

  return (
    <tr key={index} style={{ verticalAlign: "middle" }}>
      <td>{index + 1}</td>
      <td>{exam.name}</td>
      <td>{exam.type === "ydt" && "YDT"} ({exam.lang})</td>
      <td>{date}</td>
      <td>{exam.questionNumber}</td>
      <td>{exam.langTrue}</td>
      <td>{exam.langFalse}</td>
      <td>{exam.langEmpty}</td>
      <td>{exam.totalNet}</td>
      <td style={{ textAlign: "center" }}>
        <Button onClick={() => handleShow(exam)} size="sm" >Detay</Button>
      </td>
    </tr>
  )
}
