import React from 'react'
import { Button } from 'react-bootstrap';

export default function AYTExamsForStudent({ exam, index, handleShow }) {

  const date = new Date(exam.createdAt).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" })

  console.log(exam);

  return (
    <tr key={index} style={{ verticalAlign: "middle" }}>
      <td>{index + 1}</td>
      <td>{exam.name}</td>
      <td>{exam.type === "ayt" && "AYT"} ({exam.domain})</td>
      <td>{date}</td>
      <td>{exam.questionNumber}</td>
      <td>{exam.mathNet || "0"}</td>
      <td>{(Number(exam.physicsNet) + Number(exam.chemistryNet) + Number(exam.biologyNet)) || "0"}</td>
      <td>{(Number(exam.edebiyatNet) + Number(exam.tarihNet) + Number(exam.cografyaNet)) || "0"}</td>
      <td>{(Number(exam.tarih2Net) + Number(exam.cografya2Net) + Number(exam.felsefeNet) + Number(exam.dinNet)) || "0"}</td>
      <td>{exam.totalNet || "0"}</td>
      <td style={{ textAlign: "center" }}>
        <Button onClick={() => handleShow(exam)} size="sm" >Detay</Button>
      </td>
    </tr>
  )
}
