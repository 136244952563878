import React, { useEffect, useState } from 'react'
import { Navbar, Container, Table, Button, Accordion, Modal, Row, Col, Spinner } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { collection, deleteDoc, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { db } from "../../firebaseConfig"
import GradeExamsForStudent from "../student/GradeExamsForStudent"
import BranchExamsForStudent from "../student/BranchExamsForStudent"
import TYTExamsForStudent from "../student/TYTExamsForStudent"
import AYTExamsForStudent from "../student/AYTExamsForStudent"
import YDTExamsForStudent from "../student/YDTExamsForStudent"
import { useAuth } from '../../contexts/AuthContext';

export default function ExamsForStudent() {
  const [clicked, setClicked] = useState(false)

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleShow = (selected) => {
    setSelectedExam(selected)
    setShow(true)
  };

  const [showDialog, setShowDialog] = useState(false);

  const handleCloseDialog = () => setShowDialog(false);
  const handleShowDialog = () => setShowDialog(true);

  const { userData } = useAuth()

  const deleteExam = async () => {
    setClicked(true)
    if (selectedExam && userData) {
      await deleteDoc(doc(db, "exams", selectedExam.uid));

      const modified = exams.filter((exam) => exam.uid !== selectedExam.uid)

      setExams(modified)

      if (selectedExam.type === "ayt") {
        const modifiedLogs = await userData.aytExamsLogs.filter((log) => log.uid !== selectedExam.uid)
        const logRef = doc(db, "students", userData.uid);
        await updateDoc(logRef, {
          aytExamsLogs: modifiedLogs
        });
      } else if (selectedExam.type === "tyt") {
        const modifiedLogs = await userData.tytExamsLogs.filter((log) => log.uid !== selectedExam.uid)
        const logRef = doc(db, "students", userData.uid);
        await updateDoc(logRef, {
          tytExamsLogs: modifiedLogs
        });
      }

      setClicked(false)
    }
    handleClose()
  }

  const navigate = useNavigate();

  const [selectedExam, setSelectedExam] = useState();

  const [exams, setExams] = useState([])

  const [totalTYTExams, setTotalTYTExams] = useState(0)
  const [totalAYTExams, setTotalAYTExams] = useState(0)
  const [totalGradeExams, setTotalGradeExams] = useState(0)
  const [totalBranchExams, setTotalBranchExams] = useState(0)
  const [totalYDTExams, setTotalYDTExams] = useState(0)

  const [tytExams, setTYTExams] = useState([])
  const [aytExams, setAYTExams] = useState([])
  const [gradeExams, setGradeExams] = useState([])
  const [branchExams, setBranchExams] = useState([])
  const [ydtExams, setYDTExams] = useState([])

  useEffect(() => {
    if (userData) {
      const getExams = async () => {
        const exams = [];
        const querySnapshot = await getDocs(query(collection(db, "exams"), where("studentId", "==", userData.uid)));
        querySnapshot.forEach((doc) => {
          exams.push(doc.data())
        });
        exams.sort((x, y) => {
          return y.createdAt - x.createdAt
        })
        setExams(exams)
      }

      getExams()
    }
  }, [userData])

  useEffect(() => {
    const calculateTotalExams = async () => {
      let totalTYTExams = 0
      let totalAYTExams = 0
      let totalGradeExams = 0
      let totalBranchExams = 0
      let totalYDTExams = 0

      exams.forEach((exam) => {
        if (exam.type === "tyt") {
          totalTYTExams += 1
        } else if (exam.type === "ayt") {
          totalAYTExams += 1
        } else if (exam.type === "grade") {
          totalGradeExams += 1
        } else if (exam.type === "branch") {
          totalBranchExams += 1
        } else if (exam.type === "ydt") {
          totalYDTExams += 1
        }
      });

      setTotalTYTExams(totalTYTExams)
      setTotalAYTExams(totalAYTExams)
      setTotalGradeExams(totalGradeExams)
      setTotalBranchExams(totalBranchExams)
      setTotalYDTExams(totalYDTExams)
    }

    if (exams) {
      calculateTotalExams()
    }
  }, [exams])

  useEffect(() => {
    const filterExams = async () => {
      let tytExams = []
      let aytExams = []
      let gradeExams = []
      let branchExams = []
      let ydtExams = []

      exams.forEach((exam) => {
        if (exam.type === "tyt") {
          tytExams.push(exam)
        } else if (exam.type === "ayt") {
          aytExams.push(exam)
        } else if (exam.type === "grade") {
          gradeExams.push(exam)
        } else if (exam.type === "branch") {
          branchExams.push(exam)
        }
        else if (exam.type === "ydt") {
          ydtExams.push(exam)
        }
      });

      setTYTExams(tytExams)
      setAYTExams(aytExams)
      setGradeExams(gradeExams)
      setBranchExams(branchExams)
      setYDTExams(ydtExams)
    }


    if (exams) {
      filterExams()
    }
  }, [exams])

  return (
    <>
      <Navbar style={{ marginBottom: "24px" }} expand="lg" className="bg-white border-bottom">
        <Container>
          <Navbar.Brand>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {/* tıklanamayan */}
              <p style={{ margin: 0, color: "#000000A6", fontSize: "0.9rem", textDecoration: "none", cursor: "default" }}>Aktiviteler</p>

              {/* çizgi */}
              <p style={{ margin: "0 6px", color: "#000000A6", fontSize: "0.9rem" }} >/</p>

              <p style={{ margin: 0, color: "#000000", fontSize: "0.9rem", textDecoration: "none", cursor: "default", fontWeight: "500" }}>Denemeler</p>

              {/* tıklanabilir */}
              {/* <a href='/kurum-paneli/ogrenci-listesi/ogrenci-ekle' style={{ margin: 0, fontSize: "1rem", fontWeight: "500", color: "#000000", textDecoration: "none" }} >Öğrenci Ekle</a> */}
            </div>
          </Navbar.Brand>
        </Container>
      </Navbar>
      {
        exams &&
        <>
          <Container style={{ marginBottom: "24px" }}>
            <Table striped bordered responsive>
              <thead>
                <tr>
                  <th style={{ width: "14%" }}>Toplam</th>
                  <th style={{ width: "14%" }}>Sınıf</th>
                  <th style={{ width: "14%" }}>Branş</th>
                  <th style={{ width: "14%" }}>TYT</th>
                  <th style={{ width: "14%" }}>AYT</th>
                  <th style={{ width: "14%" }}>YDT</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ verticalAlign: "middle" }}>
                  <td>{exams ? exams.length : 0}</td>
                  <td>{totalGradeExams}</td>
                  <td>{totalBranchExams}</td>
                  <td>{totalTYTExams}</td>
                  <td>{totalAYTExams}</td>
                  <td>{totalYDTExams}</td>
                  <td style={{ textAlign: "center" }}>
                    <Button onClick={() => navigate("deneme-ekle")} size="sm" >Deneme Ekle</Button>
                  </td>
                </tr>
              </tbody>
            </Table>

            <Accordion style={{ marginTop: "8px" }} alwaysOpen>
              {
                gradeExams ?
                  gradeExams.length > 0 ?
                    <Accordion.Item eventKey={0}>
                      <Accordion.Header>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                          <p style={{ padding: 0, margin: 0 }}>Sınıf Denemeleri</p>
                          <p style={{ padding: 0, margin: "0 8px 0 0", }}>{totalGradeExams}</p>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body style={{ backgroundColor: "#f8f9fa6e" }}>
                        <Table striped bordered responsive>
                          <thead>
                            <tr>
                              <th style={{ width: "1%" }}>#</th>
                              <th style={{ width: "9.5%" }}>Deneme</th>
                              <th style={{ width: "9.5%" }}>Tür</th>
                              <th style={{ width: "9.5%" }}>Tarih</th>
                              <th style={{ width: "9.5%" }}>Soru Sayısı</th>
                              <th style={{ width: "9.5%" }}>Türkçe Neti</th>
                              <th style={{ width: "9.5%" }}>Sosyal Neti</th>
                              <th style={{ width: "9.5%" }}>Mat. Neti</th>
                              <th style={{ width: "9.5%" }}>Fen Neti</th>
                              <th style={{ width: "9.5%" }}>Toplam Net</th>
                              <th ></th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              gradeExams.map((exam, index) => {
                                return (
                                  <GradeExamsForStudent key={index} exam={exam} index={index} handleShow={handleShow} />
                                )
                              })
                            }
                          </tbody>
                        </Table>
                      </Accordion.Body>
                    </Accordion.Item>
                    : null
                  : null
              }
              {
                branchExams ?
                  branchExams.length > 0 ?
                    <Accordion.Item eventKey={1}>
                      <Accordion.Header>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                          <p style={{ padding: 0, margin: 0 }}>Branş Denemeleri</p>
                          <p style={{ padding: 0, margin: "0 8px 0 0", }}>{totalBranchExams}</p>

                        </div>
                      </Accordion.Header>
                      <Accordion.Body style={{ backgroundColor: "#f8f9fa6e" }} >
                        <Table striped bordered responsive>
                          <thead>
                            <tr>
                              <th style={{ width: "1%" }}>#</th>
                              <th style={{ width: "14%" }}>Deneme</th>
                              <th style={{ width: "9.5%" }}>Tür</th>
                              <th style={{ width: "9.5%" }}>Ders</th>
                              <th style={{ width: "9.5%" }}>Tarih</th>
                              <th style={{ width: "9.5%" }}>Soru Sayısı</th>
                              <th style={{ width: "8%" }}>Doğru</th>
                              <th style={{ width: "8%" }}>Yanlış</th>
                              <th style={{ width: "8%" }}>Boş</th>
                              <th style={{ width: "8%" }}>Net</th>
                              <th ></th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              branchExams.map((exam, index) => {
                                return (
                                  <BranchExamsForStudent key={index} exam={exam} index={index} handleShow={handleShow} />
                                )
                              })
                            }
                          </tbody>
                        </Table>
                      </Accordion.Body>
                    </Accordion.Item>
                    : null
                  : null
              }
              {
                tytExams ?
                  tytExams.length > 0 ?
                    <Accordion.Item eventKey={2}>
                      <Accordion.Header>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                          <p style={{ padding: 0, margin: 0 }}>TYT Denemeleri</p>
                          <p style={{ padding: 0, margin: "0 8px 0 0", }}>{totalTYTExams}</p>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body style={{ backgroundColor: "#f8f9fa6e" }} >
                        <Table striped bordered responsive>
                          <thead>
                            <tr>
                              <th style={{ width: "1%" }}>#</th>
                              <th style={{ width: "9.5%" }}>Deneme</th>
                              <th style={{ width: "9.5%" }}>Tür</th>
                              <th style={{ width: "9.5%" }}>Tarih</th>
                              <th style={{ width: "9.5%" }}>Soru Sayısı</th>
                              <th style={{ width: "9.5%" }}>Türkçe Neti</th>
                              <th style={{ width: "9.5%" }}>Sosyal Neti</th>
                              <th style={{ width: "9.5%" }}>Mat. Neti</th>
                              <th style={{ width: "9.5%" }}>Fen Neti</th>
                              <th style={{ width: "9.5%" }}>Toplam Net</th>
                              <th ></th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              tytExams.map((exam, index) => {
                                return (
                                  <TYTExamsForStudent key={index} exam={exam} index={index} handleShow={handleShow} />
                                )
                              })
                            }
                          </tbody>
                        </Table>
                      </Accordion.Body>
                    </Accordion.Item>
                    : null
                  : null
              }
              {
                aytExams ?
                  aytExams.length > 0 ?
                    <Accordion.Item eventKey={3}>
                      <Accordion.Header>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                          <p style={{ padding: 0, margin: 0 }}>AYT Denemeleri</p>
                          <p style={{ padding: 0, margin: "0 8px 0 0", }}>{totalAYTExams}</p>

                        </div>
                      </Accordion.Header>
                      <Accordion.Body style={{ backgroundColor: "#f8f9fa6e" }} >
                        <Table striped bordered responsive>
                          <thead>
                            <tr>
                              <th style={{ width: "1%" }}>#</th>
                              <th style={{ width: "9.5%" }}>Deneme</th>
                              <th style={{ width: "9.5%" }}>Tür</th>
                              <th style={{ width: "9.5%" }}>Tarih</th>
                              <th style={{ width: "9.5%" }}>Soru Sayısı</th>
                              <th style={{ width: "9.5%" }}>Mat. Neti</th>
                              <th style={{ width: "9.5%" }}>Fen Neti</th>
                              <th style={{ width: "9.5%" }}>Sosyal 1 Neti</th>
                              <th style={{ width: "9.5%" }}>Sosyal 2 Neti</th>
                              <th style={{ width: "9.5%" }}>Toplam Net</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              aytExams.map((exam, index) => {
                                return (
                                  <AYTExamsForStudent key={index} exam={exam} index={index} handleShow={handleShow} />
                                )
                              })
                            }
                          </tbody>
                        </Table>
                      </Accordion.Body>
                    </Accordion.Item>
                    : null
                  : null
              }
              {
                ydtExams ?
                  ydtExams.length > 0 ?
                    <Accordion.Item eventKey={4}>
                      <Accordion.Header>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                          <p style={{ padding: 0, margin: 0 }}>YDT Denemeleri</p>
                          <p style={{ padding: 0, margin: "0 8px 0 0", }}>{totalYDTExams}</p>

                        </div>
                      </Accordion.Header>
                      <Accordion.Body style={{ backgroundColor: "#f8f9fa6e" }}>
                        <Table striped bordered responsive>
                          <thead>
                            <tr>
                              <th style={{ width: "1%" }}>#</th>
                              <th style={{ width: "14%" }}>Deneme</th>
                              <th style={{ width: "10.75%" }}>Tür</th>
                              <th style={{ width: "10.75%" }}>Tarih</th>
                              <th style={{ width: "10.75%" }}>Soru Sayısı</th>
                              <th style={{ width: "9%" }}>Doğru</th>
                              <th style={{ width: "9%" }}>Yanlış</th>
                              <th style={{ width: "9%" }}>Boş</th>
                              <th style={{ width: "9%" }}>Net</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              ydtExams.map((exam, index) => {
                                return (
                                  <YDTExamsForStudent key={index} exam={exam} index={index} handleShow={handleShow} />
                                )
                              })
                            }
                          </tbody>
                        </Table>
                      </Accordion.Body>
                    </Accordion.Item>
                    : null
                  : null
              }
            </Accordion>

            <Modal
              show={show && !showDialog}
              onHide={handleClose}
              keyboard={false}
              backdrop="static"
            >
              {
                (selectedExam && selectedExam.type === "tyt") &&
                <>
                  <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: "1.25rem", }}>{selectedExam && selectedExam.name}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Deneme Türü: <span style={{ fontWeight: "400" }}>TYT</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Tarih: <span style={{ fontWeight: "400" }}>{new Date(selectedExam.createdAt).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" })}</span></p>
                      </Col>
                    </Row>
                    <hr />
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Türkçe Doğru: <span style={{ fontWeight: "400" }}>{selectedExam.turkishTrue}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Türkçe Yanlış: <span style={{ fontWeight: "400" }}>{selectedExam.turkishFalse}</span></p>
                      </Col>
                    </Row>
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Türkçe Boş: <span style={{ fontWeight: "400" }}>{selectedExam.turkishEmpty}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Türkçe Net: <span style={{ fontWeight: "400" }}>{selectedExam.turkishNet}</span></p>
                      </Col>
                    </Row>
                    <hr />
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Sosyal Doğru: <span style={{ fontWeight: "400" }}>{selectedExam.socialTrue}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Sosyal Yanlış: <span style={{ fontWeight: "400" }}>{selectedExam.socialFalse}</span></p>
                      </Col>
                    </Row>
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Sosyal Boş: <span style={{ fontWeight: "400" }}>{selectedExam.socialEmpty}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Sosyal Net: <span style={{ fontWeight: "400" }}>{selectedExam.socialNet}</span></p>
                      </Col>
                    </Row>
                    <hr />
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Matematik Doğru: <span style={{ fontWeight: "400" }}>{selectedExam.mathTrue}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Matematik Yanlış: <span style={{ fontWeight: "400" }}>{selectedExam.mathFalse}</span></p>
                      </Col>
                    </Row>
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Matematik Boş: <span style={{ fontWeight: "400" }}>{selectedExam.mathEmpty}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Matematik Net: <span style={{ fontWeight: "400" }}>{selectedExam.mathNet}</span></p>
                      </Col>
                    </Row>
                    <hr />
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Fen Doğru: <span style={{ fontWeight: "400" }}>{selectedExam.scienceTrue}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Fen Yanlış: <span style={{ fontWeight: "400" }}>{selectedExam.scienceFalse}</span></p>
                      </Col>
                    </Row>
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Fen Boş: <span style={{ fontWeight: "400" }}>{selectedExam.scienceEmpty}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Fen Net: <span style={{ fontWeight: "400" }}>{selectedExam.scienceNet}</span></p>
                      </Col>
                    </Row>
                    <hr />
                    <Row >
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Toplam Soru: <span style={{ fontWeight: "400" }}>{selectedExam.questionNumber}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Toplam Net: <span style={{ fontWeight: "400" }}>{selectedExam.totalNet}</span></p>
                      </Col>
                    </Row>


                  </Modal.Body>

                </>
              }
              {
                (selectedExam && selectedExam.type === "ayt" && selectedExam.domain === "SAY") &&
                <>
                  <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: "1.25rem", }}>{selectedExam && selectedExam.name}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Deneme Türü: <span style={{ fontWeight: "400" }}>AYT (SAY)</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Tarih: <span style={{ fontWeight: "400" }}>{new Date(selectedExam.createdAt).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" })}</span></p>
                      </Col>
                    </Row>
                    <hr />
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Matematik Doğru: <span style={{ fontWeight: "400" }}>{selectedExam.mathTrue}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Matematik Yanlış: <span style={{ fontWeight: "400" }}>{selectedExam.mathFalse}</span></p>
                      </Col>
                    </Row>
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Matematik Boş: <span style={{ fontWeight: "400" }}>{selectedExam.mathEmpty}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Matematik Net: <span style={{ fontWeight: "400" }}>{selectedExam.mathNet}</span></p>
                      </Col>
                    </Row>
                    <hr />
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Fizik Doğru: <span style={{ fontWeight: "400" }}>{selectedExam.physicsTrue}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Fizik Yanlış: <span style={{ fontWeight: "400" }}>{selectedExam.physicsFalse}</span></p>
                      </Col>
                    </Row>
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Fizik Boş: <span style={{ fontWeight: "400" }}>{selectedExam.physicsEmpty}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Fizik Net: <span style={{ fontWeight: "400" }}>{selectedExam.physicsNet}</span></p>
                      </Col>
                    </Row>
                    <hr />
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Kimya Doğru: <span style={{ fontWeight: "400" }}>{selectedExam.chemistryTrue}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Kimya Yanlış: <span style={{ fontWeight: "400" }}>{selectedExam.chemistryFalse}</span></p>
                      </Col>
                    </Row>
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Kimya Boş: <span style={{ fontWeight: "400" }}>{selectedExam.chemistryEmpty}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Kimya Net: <span style={{ fontWeight: "400" }}>{selectedExam.chemistryNet}</span></p>
                      </Col>
                    </Row>
                    <hr />
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Biyoloji Doğru: <span style={{ fontWeight: "400" }}>{selectedExam.biologyTrue}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Biyoloji Yanlış: <span style={{ fontWeight: "400" }}>{selectedExam.biologyFalse}</span></p>
                      </Col>
                    </Row>
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Biyoloji Boş: <span style={{ fontWeight: "400" }}>{selectedExam.biologyEmpty}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Biyoloji Net: <span style={{ fontWeight: "400" }}>{selectedExam.biologyNet}</span></p>
                      </Col>
                    </Row>
                    <hr />
                    <Row >
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Toplam Soru: <span style={{ fontWeight: "400" }}>{selectedExam.questionNumber}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Toplam Net: <span style={{ fontWeight: "400" }}>{selectedExam.totalNet}</span></p>
                      </Col>
                    </Row>


                  </Modal.Body>

                </>
              }
              {
                (selectedExam && selectedExam.type === "ayt" && selectedExam.domain === "EA") &&
                <>
                  <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: "1.25rem", }}>{selectedExam && selectedExam.name}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Deneme Türü: <span style={{ fontWeight: "400" }}>AYT (EA)</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Tarih: <span style={{ fontWeight: "400" }}>{new Date(selectedExam.createdAt).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" })}</span></p>
                      </Col>
                    </Row>
                    <hr />
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Matematik Doğru: <span style={{ fontWeight: "400" }}>{selectedExam.mathTrue}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Matematik Yanlış: <span style={{ fontWeight: "400" }}>{selectedExam.mathFalse}</span></p>
                      </Col>
                    </Row>
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Matematik Boş: <span style={{ fontWeight: "400" }}>{selectedExam.mathEmpty}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Matematik Net: <span style={{ fontWeight: "400" }}>{selectedExam.mathNet}</span></p>
                      </Col>
                    </Row>
                    <hr />
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Edebiyat Doğru: <span style={{ fontWeight: "400" }}>{selectedExam.edebiyatTrue}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Edebiyat Yanlış: <span style={{ fontWeight: "400" }}>{selectedExam.edebiyatFalse}</span></p>
                      </Col>
                    </Row>
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Edebiyat Boş: <span style={{ fontWeight: "400" }}>{selectedExam.edebiyatEmpty}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Edebiyat Net: <span style={{ fontWeight: "400" }}>{selectedExam.edebiyatNet}</span></p>
                      </Col>
                    </Row>
                    <hr />
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Tarih Doğru: <span style={{ fontWeight: "400" }}>{selectedExam.tarihTrue}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Tarih Yanlış: <span style={{ fontWeight: "400" }}>{selectedExam.tarihFalse}</span></p>
                      </Col>
                    </Row>
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Tarih Boş: <span style={{ fontWeight: "400" }}>{selectedExam.tarihEmpty}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Tarih Net: <span style={{ fontWeight: "400" }}>{selectedExam.tarihNet}</span></p>
                      </Col>
                    </Row>
                    <hr />
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Coğrafya Doğru: <span style={{ fontWeight: "400" }}>{selectedExam.cografyaTrue}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Coğrafya Yanlış: <span style={{ fontWeight: "400" }}>{selectedExam.cografyaFalse}</span></p>
                      </Col>
                    </Row>
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Coğrafya Boş: <span style={{ fontWeight: "400" }}>{selectedExam.cografyaEmpty}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Coğrafya Net: <span style={{ fontWeight: "400" }}>{selectedExam.cografyaNet}</span></p>
                      </Col>
                    </Row>
                    <hr />
                    <Row >
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Toplam Soru: <span style={{ fontWeight: "400" }}>{selectedExam.questionNumber}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Toplam Net: <span style={{ fontWeight: "400" }}>{selectedExam.totalNet}</span></p>
                      </Col>
                    </Row>
                  </Modal.Body>

                </>
              }
              {
                (selectedExam && selectedExam.type === "ayt" && selectedExam.domain === "SÖZ") &&
                <>
                  <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: "1.25rem", }}>{selectedExam && selectedExam.name}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Deneme Türü: <span style={{ fontWeight: "400" }}>AYT (SÖZ)</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Tarih: <span style={{ fontWeight: "400" }}>{new Date(selectedExam.createdAt).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" })}</span></p>
                      </Col>
                    </Row>
                    <hr />
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Edebiyat Doğru: <span style={{ fontWeight: "400" }}>{selectedExam.edebiyatTrue}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Edebiyat Yanlış: <span style={{ fontWeight: "400" }}>{selectedExam.edebiyatFalse}</span></p>
                      </Col>
                    </Row>
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Edebiyat Boş: <span style={{ fontWeight: "400" }}>{selectedExam.edebiyatEmpty}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Edebiyat Net: <span style={{ fontWeight: "400" }}>{selectedExam.edebiyatNet}</span></p>
                      </Col>
                    </Row>
                    <hr />
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Tarih 1 Doğru: <span style={{ fontWeight: "400" }}>{selectedExam.tarih1True}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Tarih 1 Yanlış: <span style={{ fontWeight: "400" }}>{selectedExam.tarih1False}</span></p>
                      </Col>
                    </Row>
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Tarih 1 Boş: <span style={{ fontWeight: "400" }}>{selectedExam.tarih1Empty}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Tarih 1 Net: <span style={{ fontWeight: "400" }}>{selectedExam.tarih1Net}</span></p>
                      </Col>
                    </Row>
                    <hr />
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Coğrafya 1 Doğru: <span style={{ fontWeight: "400" }}>{selectedExam.cografya1True}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Coğrafya 1 Yanlış: <span style={{ fontWeight: "400" }}>{selectedExam.cografya1False}</span></p>
                      </Col>
                    </Row>
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Coğrafya 1 Boş: <span style={{ fontWeight: "400" }}>{selectedExam.cografya1Empty}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Coğrafya 1 Net: <span style={{ fontWeight: "400" }}>{selectedExam.cografya1Net}</span></p>
                      </Col>
                    </Row>
                    <hr />
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Tarih 2 Doğru: <span style={{ fontWeight: "400" }}>{selectedExam.tarih2True}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Tarih 2 Yanlış: <span style={{ fontWeight: "400" }}>{selectedExam.tarih2False}</span></p>
                      </Col>
                    </Row>
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Tarih 2 Boş: <span style={{ fontWeight: "400" }}>{selectedExam.tarih2Empty}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Tarih 2 Net: <span style={{ fontWeight: "400" }}>{selectedExam.tarih2Net}</span></p>
                      </Col>
                    </Row>
                    <hr />
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Coğrafya 2 Doğru: <span style={{ fontWeight: "400" }}>{selectedExam.cografya2True}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Coğrafya 2 Yanlış: <span style={{ fontWeight: "400" }}>{selectedExam.cografya2False}</span></p>
                      </Col>
                    </Row>
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Coğrafya 2 Boş: <span style={{ fontWeight: "400" }}>{selectedExam.cografya2Empty}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Coğrafya 2 Net: <span style={{ fontWeight: "400" }}>{selectedExam.cografya2Net}</span></p>
                      </Col>
                    </Row>
                    <hr />
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Felsefe Doğru: <span style={{ fontWeight: "400" }}>{selectedExam.felsefeTrue}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Felsefe Yanlış: <span style={{ fontWeight: "400" }}>{selectedExam.felsefeFalse}</span></p>
                      </Col>
                    </Row>
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Felsefe Boş: <span style={{ fontWeight: "400" }}>{selectedExam.felsefeEmpty}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Felsefe Net: <span style={{ fontWeight: "400" }}>{selectedExam.felsefeNet}</span></p>
                      </Col>
                    </Row>
                    <hr />
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Din Kültürü Doğru: <span style={{ fontWeight: "400" }}>{selectedExam.dinTrue}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Din Kültürü Yanlış: <span style={{ fontWeight: "400" }}>{selectedExam.dinFalse}</span></p>
                      </Col>
                    </Row>
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Din Kültürü Boş: <span style={{ fontWeight: "400" }}>{selectedExam.dinEmpty}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Din Kültürü Net: <span style={{ fontWeight: "400" }}>{selectedExam.dinNet}</span></p>
                      </Col>
                    </Row>
                    <hr />
                    <Row >
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Toplam Soru: <span style={{ fontWeight: "400" }}>{selectedExam.questionNumber}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Toplam Net: <span style={{ fontWeight: "400" }}>{selectedExam.totalNet}</span></p>
                      </Col>
                    </Row>
                  </Modal.Body>

                </>
              }
              {
                (selectedExam && selectedExam.type === "ydt") &&
                <>
                  <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: "1.25rem", }}>{selectedExam && selectedExam.name}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Deneme Türü: <span style={{ fontWeight: "400" }}>YDT ({selectedExam.lang})</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Tarih: <span style={{ fontWeight: "400" }}>{new Date(selectedExam.createdAt).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" })}</span></p>
                      </Col>
                    </Row>
                    <hr />
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Doğru: <span style={{ fontWeight: "400" }}>{selectedExam.langTrue}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Yanlış: <span style={{ fontWeight: "400" }}>{selectedExam.langFalse}</span></p>
                      </Col>
                    </Row>
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Boş: <span style={{ fontWeight: "400" }}>{selectedExam.langEmpty}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Net: <span style={{ fontWeight: "400" }}>{selectedExam.totalNet}</span></p>
                      </Col>
                    </Row>
                  </Modal.Body>
                </>
              }
              {
                (selectedExam && selectedExam.type === "grade") &&
                <>
                  <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: "1.25rem", }}>{selectedExam && selectedExam.name}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Deneme Türü: <span style={{ fontWeight: "400" }}>Sınıf ({selectedExam.grade})</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Tarih: <span style={{ fontWeight: "400" }}>{new Date(selectedExam.createdAt).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" })}</span></p>
                      </Col>
                    </Row>
                    <hr />
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Türkçe Doğru: <span style={{ fontWeight: "400" }}>{selectedExam.turkishTrue}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Türkçe Yanlış: <span style={{ fontWeight: "400" }}>{selectedExam.turkishFalse}</span></p>
                      </Col>
                    </Row>
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Türkçe Boş: <span style={{ fontWeight: "400" }}>{selectedExam.turkishEmpty}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Türkçe Net: <span style={{ fontWeight: "400" }}>{selectedExam.turkishNet}</span></p>
                      </Col>
                    </Row>
                    <hr />
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Sosyal Doğru: <span style={{ fontWeight: "400" }}>{selectedExam.socialTrue}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Sosyal Yanlış: <span style={{ fontWeight: "400" }}>{selectedExam.socialFalse}</span></p>
                      </Col>
                    </Row>
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Sosyal Boş: <span style={{ fontWeight: "400" }}>{selectedExam.socialEmpty}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Sosyal Net: <span style={{ fontWeight: "400" }}>{selectedExam.socialNet}</span></p>
                      </Col>
                    </Row>
                    <hr />
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Matematik Doğru: <span style={{ fontWeight: "400" }}>{selectedExam.mathTrue}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Matematik Yanlış: <span style={{ fontWeight: "400" }}>{selectedExam.mathFalse}</span></p>
                      </Col>
                    </Row>
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Matematik Boş: <span style={{ fontWeight: "400" }}>{selectedExam.mathEmpty}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Matematik Net: <span style={{ fontWeight: "400" }}>{selectedExam.mathNet}</span></p>
                      </Col>
                    </Row>
                    <hr />
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Fen Doğru: <span style={{ fontWeight: "400" }}>{selectedExam.scienceTrue}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Fen Yanlış: <span style={{ fontWeight: "400" }}>{selectedExam.scienceFalse}</span></p>
                      </Col>
                    </Row>
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Fen Boş: <span style={{ fontWeight: "400" }}>{selectedExam.scienceEmpty}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Fen Net: <span style={{ fontWeight: "400" }}>{selectedExam.scienceNet}</span></p>
                      </Col>
                    </Row>
                    <hr />
                    <Row >
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Toplam Soru: <span style={{ fontWeight: "400" }}>{selectedExam.questionNumber}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Toplam Net: <span style={{ fontWeight: "400" }}>{selectedExam.totalNet}</span></p>
                      </Col>
                    </Row>
                  </Modal.Body>

                </>
              }
              {
                (selectedExam && selectedExam.type === "branch") &&
                <>
                  <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: "1.25rem", }}>{selectedExam && selectedExam.name}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Deneme Türü: <span style={{ fontWeight: "400" }}>Branş <br />({selectedExam.examType} {selectedExam.lesson})</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Tarih: <span style={{ fontWeight: "400" }}>{new Date(selectedExam.createdAt).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" })}</span></p>
                      </Col>
                    </Row>
                    <hr />
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Doğru: <span style={{ fontWeight: "400" }}>{selectedExam.generalTrue}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Yanlış: <span style={{ fontWeight: "400" }}>{selectedExam.generalFalse}</span></p>
                      </Col>
                    </Row>
                    <Row className='mb-1'>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Boş: <span style={{ fontWeight: "400" }}>{selectedExam.generalEmpty}</span></p>
                      </Col>
                      <Col>
                        <p style={{ margin: 0, padding: 0, fontWeight: "600", }}>Net: <span style={{ fontWeight: "400" }}>{selectedExam.totalNet}</span></p>
                      </Col>
                    </Row>
                  </Modal.Body>
                </>
              }

              <Modal.Footer>
                {
                  clicked
                    ? <Button style={{ width: "100%" }} variant="outline-secondary" disabled>
                      <Spinner
                        style={{ marginRight: "6px" }}
                        as="span"
                        size="sm"
                        aria-hidden="true"
                      />
                    </Button>
                    : <>
                      <Button onClick={handleShowDialog} disabled={clicked} style={{ width: "100%" }} variant="outline-danger" >
                        Denemeyi Sil
                      </Button>
                    </>
                }
              </Modal.Footer>
            </Modal>

            <Modal
              show={showDialog}
              onHide={handleCloseDialog}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
              </Modal.Header>
              <Modal.Body>
                Geri dönüşü olmayan bir işlem yapmak üzeresiniz. Bu denemeyi tüm verileri ile birlikte silmek istediğinizden emin misiniz?
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={() => {
                  deleteExam()
                  handleCloseDialog()
                }} variant="danger">Denemeyi Sil</Button>
              </Modal.Footer>
            </Modal>

          </Container>
        </>
      }
    </>
  )
}
