import React, { useEffect, useRef, useState } from 'react'
import { Navbar, Container, Button, Spinner, Row, Col, Card, Form, FloatingLabel, Modal } from 'react-bootstrap'
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import lessons from '../../datas/lessons.json'
import { db, storage } from '../../firebaseConfig';
import { getDownloadURL, uploadBytesResumable, ref } from 'firebase/storage';
import { addDoc, collection, doc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { uuidv4 } from '@firebase/util';
import imageCompression from 'browser-image-compression';

export default function AddNewQuestion() {

  const { userData } = useAuth()

  const [show, setShow] = useState(false);
  const [modalError, setModalError] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [clicked, setClicked] = useState(false)

  const navigate = useNavigate()

  const [selectedLesson, setSelectedLesson] = useState("Türkçe")
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [branches, setBranches] = useState(["Rehberlik", "Türkçe"]);

  const ref2 = useRef()

  const handleChange = () => {
    ref2.current.click()
  }

  const selectPhoto = e => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0]
      });
    }
  };

  useEffect(() => {
    if (selectedLesson) {
      if (selectedLesson === "Felsefe" || selectedLesson === "Sosyoloji" || selectedLesson === "Psikoloji" || selectedLesson === "Mantık") {
        setBranches(["Rehberlik", "Felsefe"])
      } else {
        setBranches(["Rehberlik", `${selectedLesson}`])
      }
    }
  }, [selectedLesson])

  const addNewQuestion = async (data) => {

    const fileName = `${uuidv4()}.${(data.image.name).split(".")[1]}`

    const questionsRef = ref(storage, `questions/${fileName}`);

    const uploadTask = uploadBytesResumable(questionsRef, data.image);

    uploadTask.on('state_changed',
      (snapshot) => {
        // const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;        
      },
      (error) => {
        setClicked(false)
        // alert("Bir sorun oluştu. Lütfen tekrar deneyin.")
        setModalError("Bir sorun oluştu. Lütfen tekrar deneyin.")
        handleShow()
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          const docRef = await addDoc(collection(db, "questions"), {
            createdAt: new Date().getTime(),
            studentId: data.studentId,
            organizationId: data.organizationId,
            lesson: data.lesson,
            image: downloadURL,
            imageFileName: fileName,
            branches: data.branches,
            status: "pending",
          });

          const washingtonRef = doc(db, "questions", docRef.id);

          await updateDoc(washingtonRef, {
            uid: docRef.id
          });

          setClicked(false)
          navigate("/ogrenci-paneli/cozulemeyen-sorular")
        });
      }
    );
  }

  const handleClick = async () => {
    setClicked(true)
    if (userData && image.preview && image.raw && selectedLesson) {
      if (image.raw.name.split(".")[1] === "jpg" || image.raw.name.split(".")[1] === "jpeg" || image.raw.name.split(".")[1] === "png") {
        // burada image compress et ve compressed imageı aşağıda yükle
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920
        }

        let compressedFile;

        try {
          compressedFile = await imageCompression(image.raw, options);
        } catch {
          setClicked(false)
          // alert("Bir sorun oluştu. Lütfen tekrar deneyin.")
          setModalError("Bir sorun oluştu. Lütfen tekrar deneyin.")
          handleShow()
        }

        if (compressedFile.size > 1000000) {
          setClicked(false)
          // alert("Bir sorun oluştu. Lütfen tekrar deneyin.")
          setImage({ preview: "", raw: "" })
          setModalError("Eklemeye çalıştığınız fotoğraf 1 MB limitini aşmaktadır.")
          handleShow()
        } else {
          const today = new Date().toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" })
          const allQuestions = [];
          const querySnapshot = await getDocs(query(collection(db, "questions"), where("studentId", "==", userData.uid)));

          querySnapshot.forEach((doc) => {
            allQuestions.push(doc.data())
          });

          const todayQuestions = allQuestions.filter((question) => new Date(question.createdAt).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }) === today)

          if (todayQuestions.length < 10) {
            try {
              await addNewQuestion({
                studentId: userData.uid,
                organizationId: userData.organizationId,
                lesson: selectedLesson,
                image: compressedFile,
                branches: branches
              })

            } catch (error) {
              setClicked(false)
              // alert("Bir sorun oluştu. Lütfen tekrar deneyin.")
              setModalError("Bir sorun oluştu. Lütfen tekrar deneyin.")
              handleShow()
            }
          } else {
            setClicked(false)
            // alert("Bir sorun oluştu. Lütfen tekrar deneyin.")
            setImage({ preview: "", raw: "" })
            setModalError("Günlük soru hakkınızın (10) tamamını kullandınız. Lütfen haklarınızın sıfırlanmasını bekleyin.")
            handleShow()
          }
        }
      }
      else {
        setClicked(false)
        // alert("Bir sorun oluştu. Lütfen tekrar deneyin.")
        setImage({ preview: "", raw: "" })
        setModalError("Sadece JPG, JPEG veya PNG uzantılı bir dosya ekleyebilirsiniz.")
        handleShow()
      }
    } else {
      setClicked(false)
      // alert("Lütfen tüm alanları tam ve doğru doldurun.")
      setModalError("Lütfen tüm alanları tam ve doğru doldurun.")
      handleShow()
    }
  }

  useEffect(() => {
    if (image && image.raw && image.preview) {
      if (!(image.raw.name.split(".")[1] === "jpg" || image.raw.name.split(".")[1] === "jpeg" || image.raw.name.split(".")[1] === "png")) {
        setImage({ preview: "", raw: "" })
        setModalError("Sadece JPG, JPEG veya PNG uzantılı bir dosya ekleyebilirsiniz.")
        handleShow()
      }
    }
  }, [image])

  const [width, setWidth] = useState(0)
  const elementRef = useRef(null);

  useEffect(() => {
    setWidth(elementRef.current.offsetWidth);
  }, [])

  return (
    <>
      <Navbar style={{ marginBottom: "24px" }} expand="lg" className="bg-white border-bottom">
        <Container>
          <Navbar.Brand>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {/* tıklanamayan */}
              <p style={{ margin: 0, color: "#000000A6", fontSize: "0.9rem", textDecoration: "none", cursor: "default" }}>Aktiviteler</p>

              {/* çizgi */}
              <p style={{ margin: "0 6px", color: "#000000A6", fontSize: "0.9rem" }} >/</p>

              <a href='/ogrenci-paneli/cozulemeyen-sorular' style={{ margin: 0, fontSize: "0.9rem", fontWeight: "400", color: "#000000A6", textDecoration: "none" }} >Çözülemeyen Sorular</a>

              <p style={{ margin: "0 6px", color: "#000000A6", fontSize: "0.9rem" }} >/</p>

              <p style={{ margin: 0, color: "#000000", fontSize: "0.9rem", textDecoration: "none", cursor: "default", fontWeight: "500" }}>Soru Ekle</p>

              {/* tıklanabilir */}
              {/* <a href='/kurum-paneli/ogrenci-listesi/ogrenci-ekle' style={{ margin: 0, fontSize: "1rem", fontWeight: "500", color: "#000000", textDecoration: "none" }} >Öğrenci Ekle</a> */}
            </div>
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Container style={{ marginBottom: "24px" }}>
        <Container style={{ display: "flex", justifyContent: "center", alignItems: "flex-start" }}>
          <Row style={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <Col lg={3} md={2} sm={1} />
            <Col lg={6} md={8} sm={10} style={{ padding: "0", maxWidth: "450px" }}>
              <Card >
                <Card.Body>
                  <Form onSubmit={e => { e.preventDefault(); }}>
                    <FloatingLabel ref={elementRef} controlId="floatingSelect" label="Ders *">
                      <Form.Select className="mb-3" value={selectedLesson} onChange={(e) => setSelectedLesson(e.target.value)} aria-label="Default select example">
                        {
                          lessons && lessons.map((lesson, index) => (
                            <option key={index} value={lesson.title}>{lesson.title}</option>
                          ))
                        }
                      </Form.Select>
                    </FloatingLabel>

                    {
                      <div>
                        <label htmlFor="upload-button" style={{ width: "100%" }}>
                          {image.preview ? (
                            <img className='mb-3' src={image.preview} alt="" style={{ width: "100%", maxHeight: width, objectFit: "contain" }} />
                          ) : (
                            <Button className='mb-3' onClick={handleChange} style={{ width: "100%" }} variant="outline-primary" >
                              Fotoğraf Seç
                            </Button>
                          )}
                        </label>
                        <input
                          ref={ref2}
                          type="file"
                          id="upload-button"
                          style={{ display: "none" }}
                          onChange={selectPhoto}
                        />
                        <br />                      </div>
                    }

                    <div style={{ margin: "0 0 12px 0", display: "flex", flexDirection: "row", justifyContent: "center", width: "100%", padding: 0 }}>
                      <Form.Text style={{ marginTop: 0, textAlign: "center" }} className="text-muted">
                        Yıldız (*) işareti bulunan alanları doldurmak zorunludur.
                      </Form.Text>
                    </div>

                    {
                      clicked
                        ? <Button style={{ width: "100%" }} variant="outline-secondary" disabled>
                          <Spinner
                            style={{ marginRight: "6px" }}
                            as="span"
                            size="sm"
                            aria-hidden="true"
                          />
                        </Button>
                        : <Button onClick={handleClick} style={{ width: "100%" }} variant="primary" >
                          Soru Ekle
                        </Button>
                    }

                  </Form>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={3} md={2} sm={1} />
          </Row>
        </Container>

        <Modal
          show={show}
          onHide={handleClose}
          keyboard={false}
        >
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
            {modalError}
          </Modal.Body>

        </Modal>
      </Container>
    </>
  )
}


