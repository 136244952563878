import React, { useEffect, useState } from 'react'
import { Navbar, Container, Button, Spinner, Row, Col, Card, Form, FloatingLabel, Modal } from 'react-bootstrap'
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

export default function AddNewOrganization() {

  const [show, setShow] = useState(false);
  const [modalError, setModalError] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [clicked, setClicked] = useState(false)

  const { checkIsExist, createNewOrganization, generatePassword } = useAuth();

  const navigate = useNavigate()

  const handleClick = async () => {
    setClicked(true)
    if (name && tag && adress && phone.length === 10 && username && password.length >= 6) {
      const isExist = await checkIsExist(username)
      if (!isExist) {
        try {
          await createNewOrganization({
            name: name.trim(),
            tag: tag.trim(),
            email: `${username.trim()}@dereceyks.com`,
            adress: adress.trim(),
            phone: phone.trim(),
            username: username.trim(),
            password: password.trim(),
            role: "organization",
          })
          setClicked(false)
          navigate("/admin-paneli/kurum-listesi")
        } catch {
          setClicked(false)
          // alert("Bir sorun oluştu. Lütfen tekrar deneyin.")
          setModalError("Bir sorun oluştu. Lütfen tekrar deneyin.")
          handleShow()
        }
      } else {
        setClicked(false)
        // alert("Bu kullanıcı adını kullanan bir hesap mevcut.")
        setModalError("Bu kullanıcı adını kullanan bir hesap mevcut.")
        handleShow()
      }
    } else {
      setClicked(false)
      // alert("Lütfen tüm alanları tam ve doğru doldurun.")
      setModalError("Lütfen tüm alanları tam ve doğru doldurun.")
      handleShow()
    }
  }

  const [name, setName] = useState("")
  const [tag, setTag] = useState("")
  const [adress, setAdress] = useState("")
  const [phone, setPhone] = useState("")
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")

  useEffect(() => {
    if (generatePassword && !password) {
      const getPassword = async () => {
        const suggestion = await generatePassword()
        setPassword(suggestion)
      }

      getPassword()
    }
  }, [generatePassword, password])

  return (
    <>
      <Navbar style={{ marginBottom: "24px" }} expand="lg" className="bg-white border-bottom">
        <Container>
          <Navbar.Brand>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {/* tıklanamayan */}
              <p style={{ margin: 0, color: "#000000A6", fontSize: "0.9rem", textDecoration: "none", cursor: "default" }}>Kurumlar</p>

              {/* çizgi */}
              <p style={{ margin: "0 6px", color: "#000000A6", fontSize: "0.9rem" }} >/</p>

              <a href='/admin-paneli/kurum-listesi' style={{ margin: 0, fontSize: "0.9rem", fontWeight: "400", color: "#000000A6", textDecoration: "none" }} >Kurum Listesi</a>

              <p style={{ margin: "0 6px", color: "#000000A6", fontSize: "0.9rem" }} >/</p>

              <p style={{ margin: 0, color: "#000000", fontSize: "0.9rem", textDecoration: "none", cursor: "default", fontWeight: "500" }}>Kurum Ekle</p>

              {/* tıklanabilir */}
              {/* <a href='/kurum-paneli/ogrenci-listesi/ogrenci-ekle' style={{ margin: 0, fontSize: "1rem", fontWeight: "500", color: "#000000", textDecoration: "none" }} >Öğrenci Ekle</a> */}
            </div>
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Container style={{ marginBottom: "24px" }}>
        <Container style={{ display: "flex", justifyContent: "center", alignItems: "flex-start" }}>
          <Row style={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <Col lg={3} md={2} sm={1} />
            <Col lg={6} md={8} sm={10} style={{ padding: "0", maxWidth: "450px" }}>
              <Card >
                <Card.Body>
                  <Form onSubmit={e => { e.preventDefault(); }}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <FloatingLabel
                        controlId="floatingInput1"
                        label="Kurum *"
                        className="mb-3"
                      >
                        <Form.Control type='text' placeholder="Kurum" value={name} onChange={(e) => setName(e.target.value)} />
                      </FloatingLabel>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <FloatingLabel
                        controlId="floatingInput1"
                        label="Kısaltma *"
                        className="mb-3"
                      >
                        <Form.Control type='text' placeholder="Kısaltma" value={tag} onChange={(e) => setTag(e.target.value.trim().toUpperCase())} />
                      </FloatingLabel>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <FloatingLabel
                        controlId="floatingInput2"
                        label="Adres *"
                        className="mb-3"
                      >
                        <Form.Control type='text' placeholder="Adres" value={adress} onChange={(e) => setAdress(e.target.value)} />
                      </FloatingLabel>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <FloatingLabel
                        controlId="floatingInput3"
                        label="Telefon *"
                        className="mb-3"
                      >
                        <Form.Control type='text' placeholder="Telefon" value={phone} onChange={(e) => setPhone(e.target.value.trim())} maxLength={10} />
                      </FloatingLabel>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <FloatingLabel
                        controlId="floatingInput4"
                        label="Kullanıcı Adı *"
                        className="mb-3"
                      >
                        <Form.Control type='text' placeholder="Kullanıcı Adı" value={username} onChange={(e) => setUsername(e.target.value.trim())} />
                      </FloatingLabel>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <FloatingLabel controlId="floatingPassword" label="Parola *">
                        <Form.Control type="text" placeholder="Parola" autoComplete='new-password' value={password} onChange={(e) => setPassword(e.target.value.trim())} />
                      </FloatingLabel>
                    </Form.Group>

                    <div style={{ margin: "0 0 12px 0", display: "flex", flexDirection: "row", justifyContent: "center", width: "100%", padding: 0 }}>
                        <Form.Text style={{ marginTop: 0 }} className="text-muted">
                          Yıldız (*) işareti bulunan alanları doldurmak zorunludur.
                        </Form.Text>
                      </div>

                    {
                      clicked
                        ? <Button style={{ width: "100%" }} variant="primary" disabled>
                          <Spinner
                            style={{ marginRight: "6px" }}
                            as="span"
                            size="sm"
                            aria-hidden="true"
                          />
                        </Button>
                        : <Button onClick={handleClick} style={{ width: "100%" }} variant="primary" >
                          Kurum Ekle
                        </Button>
                    }

                  </Form>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={3} md={2} sm={1} />
          </Row>
        </Container>

        <Modal
          show={show}
          onHide={handleClose}
          keyboard={false}
        >
          <Modal.Header closeButton>
            {/* <Modal.Title>{modalTitle}</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            {modalError}
          </Modal.Body>
        </Modal>

      </Container>
    </>
  )
}
