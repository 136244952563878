import React from 'react'
import { Button } from 'react-bootstrap';

export default function BranchExamsForStudent({ exam, index, handleShow }) {

  let date = exam.createdAt

  date = new Date(date).toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" })

  return (
    <tr key={index} style={{ verticalAlign: "middle" }}>
      <td>{index + 1}</td>
      <td>{exam.name}</td>
      <td>{exam.type === "branch" && "Branş"} ({exam.examType})</td>
      <td>{exam.lesson}</td>
      <td>{date}</td>
      <td>{exam.questionNumber}</td>
      <td>{exam.generalTrue}</td>
      <td>{exam.generalFalse}</td>
      <td>{exam.generalEmpty}</td>
      <td>{exam.totalNet}</td>
      <td style={{ textAlign: "center" }}>
        <Button onClick={() => handleShow(exam)} size="sm">Detay</Button>
      </td>
    </tr>
  )
}

