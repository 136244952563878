import { arrayUnion, doc, updateDoc } from 'firebase/firestore';
import React, { useState } from 'react'
import { Navbar, Container, Accordion, Col, Row, Form, Modal, Button, FloatingLabel } from 'react-bootstrap'
import { db } from '../../firebaseConfig';
import { useAuth } from '../../contexts/AuthContext';
import lessons from "../../datas/lessons.json"
import subjects from "../../datas/subjects.json"
import { uuidv4 } from '@firebase/util';

export default function StudyTimeForStudent() {

  const [showDialog2, setShowDialog2] = useState(false);

  const handleCloseDialog2 = () => setShowDialog2(false);
  const handleShowDialog2 = () => setShowDialog2(true);

  const { realTimeUserData, createNewActivity } = useAuth()

  const [showDialog, setShowDialog] = useState(false);

  const handleCloseDialog = () => setShowDialog(false);
  const handleShowDialog = () => setShowDialog(true);

  const [clickedLessonData, setClickedLessonData] = useState("")
  const [clickedSubjectData, setClickedSubjectData] = useState("")

  const handleFocus = (event) => event.target.select();

  const [newMinutes, setNewMinutes] = useState("0")
  const [error, setError] = useState(false)

  const checkSubject = async () => {
    if (clickedLessonData && clickedSubjectData && newMinutes) {

      if (newMinutes[0] !== "0") {
        setError(false)
        const codingKey = `${clickedSubjectData.grade}-${clickedLessonData.codingKey}-${clickedSubjectData.codingKey}`

        if (realTimeUserData && realTimeUserData.studyTime) {
          if (realTimeUserData.studyTime[codingKey]) {
            const oldValue = realTimeUserData.studyTime[codingKey]
            const newValue = String(Number(oldValue) + Number(newMinutes))
            const studentRef = doc(db, "students", realTimeUserData.uid);
            await updateDoc(studentRef, {
              studyTime: {
                ...realTimeUserData.studyTime,
                [codingKey]: newValue
              }
            });

            const logRef = doc(db, "students", realTimeUserData.uid);
            await updateDoc(logRef, {
              studyTimeLogs: arrayUnion({
                id: uuidv4(),
                value: newMinutes,
                date: new Date().toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
                timestamp: new Date().getTime()
              })
            });

            await createNewActivity(`${clickedLessonData.title} dersinin ${clickedSubjectData.title} konusunu ${newMinutes} dakika çalıştı.`)
          } else {
            const studentRef = doc(db, "students", realTimeUserData.uid);
            await updateDoc(studentRef, {
              studyTime: {
                ...realTimeUserData.studyTime,
                [codingKey]: newMinutes
              }
            });

            const logRef = doc(db, "students", realTimeUserData.uid);
            await updateDoc(logRef, {
              studyTimeLogs: arrayUnion({
                id: uuidv4(),
                value: newMinutes,
                date: new Date().toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
                timestamp: new Date().getTime()
              })
            });

            await createNewActivity(`${clickedLessonData.title} dersinin ${clickedSubjectData.title} konusunu ${newMinutes} dakika çalıştı.`)
          }
        } else {
          const studentRef = doc(db, "students", realTimeUserData.uid);

          await updateDoc(studentRef, {
            studyTime: {
              [codingKey]: newMinutes
            }
          });

          const logRef = doc(db, "students", realTimeUserData.uid);
          await updateDoc(logRef, {
            studyTimeLogs: arrayUnion({
              id: uuidv4(),
              value: newMinutes,
              date: new Date().toLocaleDateString("tr", { day: "2-digit", month: "2-digit", year: "numeric" }),
              timestamp: new Date().getTime()
            })
          });

          await createNewActivity(`${clickedLessonData.title} dersinin ${clickedSubjectData.title} konusunu ${newMinutes} dakika çalıştı.`)
        }

        setNewMinutes("0")
        handleCloseDialog()

      } else {
        setError(true)
        setNewMinutes("0")
      }
    }
  }

  const resetSubject = async () => {
    if (clickedLessonData && clickedSubjectData) {
      const codingKey = `${clickedSubjectData.grade}-${clickedLessonData.codingKey}-${clickedSubjectData.codingKey}`

      const studentRef = doc(db, "students", realTimeUserData.uid);

      const copyOfStudyTimeMap = realTimeUserData.studyTime

      delete copyOfStudyTimeMap[codingKey]

      await updateDoc(studentRef, {
        studyTime: {
          ...copyOfStudyTimeMap
        }
      });

      setNewMinutes("0")
      handleCloseDialog()
    }
  }

  return (
    <>
      <Navbar style={{ marginBottom: "24px" }} expand="lg" className="bg-white border-bottom">
        <Container>
          <Navbar.Brand>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {/* tıklanamayan */}
              <p style={{ margin: 0, color: "#000000A6", fontSize: "0.9rem", textDecoration: "none", cursor: "default" }}>Aktiviteler</p>

              {/* çizgi */}
              <p style={{ margin: "0 6px", color: "#000000A6", fontSize: "0.9rem" }} >/</p>

              <p style={{ margin: 0, color: "#000000", fontSize: "0.9rem", textDecoration: "none", cursor: "default", fontWeight: "500" }}>Ders Çalışma Süresi</p>

              {/* tıklanabilir */}
              {/* <a href='/kurum-paneli/ogrenci-listesi/ogrenci-ekle' style={{ margin: 0, fontSize: "1rem", fontWeight: "500", color: "#000000", textDecoration: "none" }} >Öğrenci Ekle</a> */}
            </div>
          </Navbar.Brand>
        </Container>
      </Navbar>
      {
        (realTimeUserData) &&
        <Container style={{ marginBottom: "24px" }}>
          <Container style={{ display: "flex", justifyContent: "center", alignItems: "flex-start" }}>
            <Row style={{ width: "100%", display: "flex", justifyContent: "center" }}>
              <Col lg={3} md={2} sm={1} />
              <Col lg={6} md={8} sm={10} style={{ padding: "0", maxWidth: "450px" }}>
                <Accordion alwaysOpen>
                  {
                    ["9", "10", "11", "12"].map((grade, index) => {

                      let totalMinutes = 0

                      lessons.forEach((lesson) => {
                        subjects.forEach((subject) => {
                          if (subject.lessonId === lesson.uid && subject.grade === grade) {
                            const codingKey = `${subject.grade}-${lesson.codingKey}-${subject.codingKey}`

                            if (realTimeUserData.studyTime) {
                              if (realTimeUserData.studyTime[codingKey]) {
                                totalMinutes += Number(realTimeUserData.studyTime[codingKey])
                              }
                            }
                          }
                        })
                      })

                      let hours = ~~(totalMinutes / 60)
                      let minutes = totalMinutes - (hours * 60)

                      if (hours < 10) hours = `0${hours}`
                      if (minutes < 10) minutes = `0${minutes}`

                      return (
                        <Accordion.Item key={index} eventKey={index + (Number(grade) * 100)}>
                          <Accordion.Header>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                              <p style={{ padding: 0, margin: 0 }}>{grade}. Sınıf</p>
                              <p style={{ padding: 0, margin: "0 8px 0 0", }}>{hours}:{minutes}</p>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body style={{ backgroundColor: "#F8F9FA" }}>
                            {
                              lessons && lessons.map((lesson, index) => {
                                if (lesson.grades.includes(grade)) {

                                  let totalMinutes2 = 0

                                  subjects.forEach((subject) => {
                                    if (subject.lessonId === lesson.uid && subject.grade === grade) {
                                      const codingKey = `${subject.grade}-${lesson.codingKey}-${subject.codingKey}`

                                      if (realTimeUserData.studyTime) {
                                        if (realTimeUserData.studyTime[codingKey]) {
                                          totalMinutes2 += Number(realTimeUserData.studyTime[codingKey])
                                        }
                                      }
                                    }
                                  })

                                  let hours2 = ~~(totalMinutes2 / 60)
                                  let minutes2 = totalMinutes2 - (hours2 * 60)

                                  if (hours2 < 10) hours2 = `0${hours2}`
                                  if (minutes2 < 10) minutes2 = `0${minutes2}`

                                  return (
                                    <Accordion.Item key={index} eventKey={index + (Number(grade) * 200)}>
                                      <Accordion.Header>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                                          <p style={{ padding: 0, margin: 0 }}>{lesson.title}</p>
                                          <p style={{ padding: 0, margin: "0 8px 0 0", }}>{hours2}:{minutes2}</p>
                                        </div>
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        <Container style={{ display: "flex", flexDirection: "column", gap: "16px 0" }}>
                                          {
                                            subjects && subjects.map((subject, index) => {
                                              if (subject.grade === grade && subject.lessonId === lesson.uid) {

                                                let totalMinutes3 = 0

                                                const codingKey = `${subject.grade}-${lesson.codingKey}-${subject.codingKey}`

                                                if (realTimeUserData.studyTime) {
                                                  if (realTimeUserData.studyTime[codingKey]) {
                                                    totalMinutes3 = Number(realTimeUserData.studyTime[codingKey])
                                                  }
                                                }

                                                let hours3 = ~~(totalMinutes3 / 60)
                                                let minutes3 = totalMinutes3 - (hours3 * 60)

                                                if (hours3 < 10) hours3 = `0${hours3}`
                                                if (minutes3 < 10) minutes3 = `0${minutes3}`

                                                return (
                                                  <Row key={index}>
                                                    <div onClick={() => {
                                                      setClickedLessonData(lesson)
                                                      setClickedSubjectData(subject)
                                                      handleShowDialog()
                                                    }} style={{ backgroundColor: "#fff", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", border: "1px solid #dee2e6", padding: "12px 16px", borderRadius: "0.375rem" }}>
                                                      <p style={{ margin: 0, fontSize: "1rem", padding: 0 }}>{subject.title}</p>
                                                      <Form style={{ display: "flex", alignItems: "center", marginLeft: "16px" }}>
                                                        <p style={{ padding: 0, margin: "0", }}>{hours3}:{minutes3}</p>
                                                      </Form>
                                                    </div>
                                                  </Row>
                                                )
                                              } else {
                                                return null
                                              }
                                            })
                                          }
                                        </Container>

                                      </Accordion.Body>
                                    </Accordion.Item>
                                  )
                                } else {
                                  return null
                                }
                              })
                            }
                          </Accordion.Body>
                        </Accordion.Item>
                      )
                    })
                  }
                </Accordion>
              </Col>
              <Col lg={3} md={2} sm={1} />
            </Row>
          </Container>

          <Modal
            show={showDialog && !showDialog2}
            onHide={handleCloseDialog}
            backdrop="static"
            keyboard={false}
            onExit={() => {
              setError(false)
              setNewMinutes("0")
            }}
          >
            <Modal.Header closeButton>

              <p style={{ padding: 0, margin: 0, flex: 1, fontWeight: "500", }}><span style={{ fontWeight: "400", color: "#000000A6" }}>{clickedLessonData && `${clickedLessonData.title} / `}</span>{clickedSubjectData && `${clickedSubjectData.title}`}</p>

            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={e => { e.preventDefault(); }}>
                <Form.Group controlId="formBasicEmail">
                  <FloatingLabel
                    controlId="floatingInput1"
                    label="Kaç dakika çalıştın?"
                  >
                    <Form.Control onFocus={handleFocus} type="number" value={newMinutes} onChange={(e) => setNewMinutes(e.target.value.trim())} />
                  </FloatingLabel>
                </Form.Group>

                {
                  error && <div style={{ marginTop: "12px", textAlign: "center", display: "flex", flexDirection: "row", justifyContent: "center", width: "100%", padding: 0 }}>
                    <Form.Text className="text-muted">
                      Lütfen geçerli bir sayı giriniz.
                    </Form.Text>
                  </div>
                }
              </Form>

            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-danger" onClick={() => {
                handleShowDialog2()
                // handleCloseDialog()
              }}>
                Sıfırla
              </Button>
              <Button disabled={(newMinutes[0] === "0" || !newMinutes) ? true : false} onClick={() => {
                setError(false)
                checkSubject()
                handleCloseDialog()
              }} variant={"primary"}>
                Süre Ekle
              </Button>
            </Modal.Footer>
          </Modal>


          <Modal
            show={showDialog2}
            onHide={handleCloseDialog2}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
              Geri dönüşü olmayan bir işlem yapmak üzeresiniz. Bu konuya ait çalışma süresini sıfırlamak istediğinizden emin misiniz?
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => {
                resetSubject()
                handleCloseDialog()
                handleCloseDialog2()
              }} variant="danger">Süreyi Sıfırla</Button>
            </Modal.Footer>
          </Modal>
        </Container>
      }
    </>
  )
}
