import React, { useEffect, useRef, useState } from 'react'
import { Navbar, Container, Table, Button, Accordion, Modal, Col, Row, Spinner, } from 'react-bootstrap'
import { useAuth } from '../../contexts/AuthContext'
import { useNavigate } from 'react-router-dom'
import { collection, deleteDoc, doc, getDocs, query, where } from 'firebase/firestore'
import { db, storage } from '../../firebaseConfig'
import lessons from '../../datas/lessons.json'
import QuestionRow from "./QuestionRow"
import { deleteObject, ref } from 'firebase/storage'

export default function UnsolvableQuestionsForStudent() {

  const [questions, setQuestions] = useState([])
  const [solvedQuestions, setSolvedQuestions] = useState(0)
  const [pendingQuestions, setPendingQuestions] = useState(0)

  const deleteQuestion = async (question) => {
    const desertRef = ref(storage, `questions/${question.imageFileName}`);

    await deleteObject(desertRef).then(async () => {
      await deleteDoc(doc(db, "questions", question.uid));
    })

    const modified = questions.filter((q) => q.uid !== question.uid)

    setQuestions(modified)

    handleClose()
    handleCloseDialog()
  }

  const [selectedQuestion, setSelectedQuestion] = useState();

  const { userData } = useAuth()

  const [clicked, setClicked] = useState(false)

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleShow = (selected) => {
    setSelectedQuestion(selected)
    setShow(true)
  };

  const [showDialog, setShowDialog] = useState(false);

  const handleCloseDialog = () => setShowDialog(false);
  const handleShowDialog = () => setShowDialog(true);

  useEffect(() => {
    if (userData) {
      const getQuestions = async () => {
        const questions = [];
        const querySnapshot = await getDocs(query(collection(db, "questions"), where("studentId", "==", userData.uid)));
        querySnapshot.forEach((doc) => {
          questions.push(doc.data())
        });
        questions.sort((x, y) => {
          return y.createdAt - x.createdAt
        })
        setQuestions(questions)
      }

      getQuestions()
    }
  }, [userData])

  const navigate = useNavigate();

  useEffect(() => {
    const calculateQuestions = async () => {
      let solved = 0
      let pending = 0

      questions.forEach((question) => {
        if (question.status === "pending") {
          pending += 1
        } else if (question.status === "solved") {
          solved += 1
        }
      });

      setSolvedQuestions(solved)
      setPendingQuestions(pending)
    }

    if (questions) {
      calculateQuestions()
    }
  }, [questions])


  const [width, setWidth] = useState(0)
  const elementRef = useRef(null);

  useEffect(() => {
    setWidth(elementRef.current.offsetWidth);
  }, [])

  return (
    <>
      <Navbar style={{ marginBottom: "24px" }} expand="lg" className="bg-white border-bottom">
        <Container>
          <Navbar.Brand>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {/* tıklanamayan */}
              <p style={{ margin: 0, color: "#000000A6", fontSize: "0.9rem", textDecoration: "none", cursor: "default" }}>Aktiviteler</p>

              {/* çizgi */}
              <p style={{ margin: "0 6px", color: "#000000A6", fontSize: "0.9rem" }} >/</p>

              <p style={{ margin: 0, color: "#000000", fontSize: "0.9rem", textDecoration: "none", cursor: "default", fontWeight: "500" }}>Çözülemeyen Sorular</p>

              {/* tıklanabilir */}
              {/* <a href='/kurum-paneli/ogrenci-listesi/ogrenci-ekle' style={{ margin: 0, fontSize: "1rem", fontWeight: "500", color: "#000000", textDecoration: "none" }} >Öğrenci Ekle</a> */}
            </div>
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Container style={{ marginBottom: "24px" }}>
        {
          <>
            <Container style={{ marginBottom: "24px" }}>
              <Table striped bordered responsive>
                <thead>
                  <tr>
                    <th style={{ width: "25%" }}>Toplam</th>
                    <th style={{ width: "25%" }}>Bekleyen</th>
                    <th style={{ width: "14%" }}>Çözülmüş</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ verticalAlign: "middle" }}>
                    <td>{questions ? questions.length : 0}</td>
                    <td>{pendingQuestions}</td>
                    <td>{solvedQuestions}</td>
                    <td style={{ textAlign: "center" }}>
                      <Button onClick={() => navigate("soru-ekle")} size="sm" >Soru Ekle</Button>
                    </td>
                  </tr>
                </tbody>
              </Table>


              {
                questions && <Accordion style={{ marginTop: "8px" }} alwaysOpen>
                  {
                    (lessons) &&
                    lessons.map((lesson, index) => {

                      const questionsForThisLesson = questions.filter((question) => question.lesson === lesson.title)

                      if (questionsForThisLesson && questionsForThisLesson.length > 0) {
                        return (
                          <Accordion.Item key={index} eventKey={index}>
                            <Accordion.Header>
                              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                                <p style={{ padding: 0, margin: 0 }}>{lesson.title}</p>
                                <p style={{ padding: 0, margin: "0 8px 0 0", }}>{questionsForThisLesson ? questionsForThisLesson.length : 0}</p>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body style={{ backgroundColor: "#f8f9fa6e" }}>
                              <Table striped bordered responsive>
                                <thead>
                                  <tr>
                                    <th style={{ width: "1%" }}>#</th>
                                    <th style={{ width: "200px", minWidth: "200px", maxWidth: "200px" }}>Soru</th>
                                    <th style={{ width: "auto" }}>Tarih</th>
                                    <th style={{ width: "auto" }}>Durum</th>
                                    <th style={{ width: "15%" }}></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    questionsForThisLesson.map((question, index) => {
                                      return (
                                        <QuestionRow key={index} question={question} index={index} handleShow={handleShow} setSelectedQuestion={setSelectedQuestion} handleShowDialog={handleShowDialog} />
                                      )
                                    })
                                  }
                                </tbody>
                              </Table>
                            </Accordion.Body>
                          </Accordion.Item>
                        )
                      } else {
                        return null
                      }
                    })
                  }
                </Accordion>
              }

              <Modal
                show={show && !showDialog}
                onHide={handleClose}
                keyboard={false}
                backdrop="static"
                ref={elementRef}

              >
                {

                  <>
                    <Modal.Header closeButton>
                      {/* <Modal.Title style={{ fontSize: "1.25rem", }}></Modal.Title> */}
                    </Modal.Header>
                    <Modal.Body>
                      {
                        selectedQuestion &&
                        <a href={selectedQuestion.solutionImage} target='_blank' rel='noreferrer'>
                          <img src={selectedQuestion.solutionImage} alt="" style={{ width: "100%", maxHeight: width, objectFit: "contain" }} />
                        </a>
                      }
                    </Modal.Body>
                  </>
                }

                <Modal.Footer>
                  {
                    clicked
                      ? <Button style={{ width: "100%" }} variant="outline-secondary" disabled>
                        <Spinner
                          style={{ marginRight: "6px" }}
                          as="span"
                          size="sm"
                          aria-hidden="true"
                        />
                      </Button>
                      : <>
                        <Button onClick={handleShowDialog} disabled={clicked} style={{ width: "100%" }} variant="outline-danger" >
                          Soruyu Kaldır
                        </Button>
                      </>
                  }
                </Modal.Footer>
              </Modal>

              <Modal
                show={showDialog}
                onHide={handleCloseDialog}
                backdrop="static"
                keyboard={false}
              >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                  Geri dönüşü olmayan bir işlem yapmak üzeresiniz. Bu soruyu tüm verileri ile birlikte silmek istediğinizden emin misiniz?
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={() => {
                    deleteQuestion(selectedQuestion)
                  }} variant="danger">Soruyu Kaldır</Button>
                </Modal.Footer>
              </Modal>

            </Container>
          </>
        }
      </Container>
    </>
  )
}
