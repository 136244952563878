import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBmmF1QLeBBM7O1R8OxskMcpMrfFVZalBc",
  authDomain: "dereceyks-b0323.firebaseapp.com",
  projectId: "dereceyks-b0323",
  storageBucket: "dereceyks-b0323.appspot.com",
  messagingSenderId: "818405847377",
  appId: "1:818405847377:web:d13aa6230d56eb31be6370"
};

const app = initializeApp(firebaseConfig);
const secondaryApp = initializeApp(firebaseConfig, "Secondary")

const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

const secondaryAuth = getAuth(secondaryApp)

export { auth, db, secondaryAuth, storage }