import { collection, getDocs, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { Navbar, Container, Table, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { db } from '../../firebaseConfig';
import { useAuth } from '../../contexts/AuthContext';

export default function SolvedQuestionsForTeacher() {

  const navigate = useNavigate();

  const [students, setStudents] = useState([])

  const { userData } = useAuth();

  useEffect(() => {
    if (userData) {
      const getStudents = async () => {
        const students = [];
        const querySnapshot = await getDocs(query(collection(db, "students"), where("organizationId", "==", userData.organizationId), where("teachers", "array-contains", userData.uid)));
        querySnapshot.forEach((doc) => {
          students.push(doc.data())
        });
        students.sort((x, y) => {
          return y.createdAt - x.createdAt
        })
        setStudents(students)
      }

      getStudents()
    }
  }, [userData])

  // const [nineGrade, setNineGrade] = useState(0)
  // const [tenGrade, setTenGrade] = useState(0)
  // const [elevenGrade, setElevenGrade] = useState(0)
  // const [twelveGrade, setTwelveGrade] = useState(0)
  // const [gradGrade, setGradGrade] = useState(0)

  // const [sayCount, setSayCount] = useState(0)
  // const [eaCount, setEaCount] = useState(0)
  // const [sozCount, setSozCount] = useState(0)
  // const [dilCount, setDilCount] = useState(0)

  // useEffect(() => {
  //   if (students) {
  //     students.forEach((student) => {
  //       switch (student.grade) {
  //         case "9":
  //           setNineGrade((prev) => prev + 1)
  //           break;
  //         case "10":
  //           setTenGrade((prev) => prev + 1)
  //           break;
  //         case "11":
  //           setElevenGrade((prev) => prev + 1)
  //           break;
  //         case "12":
  //           setTwelveGrade((prev) => prev + 1)
  //           break;
  //         case "Mezun":
  //           setGradGrade((prev) => prev + 1)
  //           break;
  //         default:
  //           break;
  //       }
  //     })

  //     students.forEach((student) => {
  //       switch (student.domain) {
  //         case "SAY":
  //           setSayCount((prev) => prev + 1)
  //           break;
  //         case "EA":
  //           setEaCount((prev) => prev + 1)
  //           break;
  //         case "SÖZ":
  //           setSozCount((prev) => prev + 1)
  //           break;
  //         case "DİL":
  //           setDilCount((prev) => prev + 1)
  //           break;
  //         default:
  //           break;
  //       }
  //     })
  //   }
  // }, [students])

  return (
    <>
      <Navbar style={{ marginBottom: "24px" }} expand="lg" className="bg-white border-bottom">
        <Container>
          <Navbar.Brand>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {/* tıklanamayan */}
              <p style={{ margin: 0, color: "#000000A6", fontSize: "0.9rem", textDecoration: "none", cursor: "default" }}>Öğrenciler</p>

              {/* çizgi */}
              <p style={{ margin: "0 6px", color: "#000000A6", fontSize: "0.9rem" }} >/</p>

              <p style={{ margin: 0, color: "#000000", fontSize: "0.9rem", textDecoration: "none", cursor: "default", fontWeight: "500" }}>Çözülen Soru Sayısı</p>

              {/* tıklanabilir */}
              {/* <a href='/kurum-paneli/ogrenci-listesi/ogrenci-ekle' style={{ margin: 0, fontSize: "1rem", fontWeight: "500", color: "#000000", textDecoration: "none" }} >Öğrenci Ekle</a> */}
            </div>
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Container style={{ marginBottom: "24px" }}>
        {/* <Table  striped bordered responsive>
          <thead>
            <tr>
              <th style={{ width: "8.77%" }}>Toplam</th>
              <th style={{ width: "8.77%" }}>9. Sınıf</th>
              <th style={{ width: "8.77%" }}>10. Sınıf</th>
              <th style={{ width: "8.77%" }}>11. Sınıf</th>
              <th style={{ width: "8.77%" }}>12. Sınıf</th>
              <th style={{ width: "8.77%" }}>Mezun</th>
              <th style={{ width: "8.77%" }}>SAY</th>
              <th style={{ width: "8.77%" }}>EA</th>
              <th style={{ width: "8.77%" }}>SÖZ</th>
              <th style={{ width: "8.77%" }}>DİL</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr style={{ verticalAlign: "middle" }}>
              <td>{students ? students.length : 0}</td>
              <td>{nineGrade}</td>
              <td>{tenGrade}</td>
              <td>{elevenGrade}</td>
              <td>{twelveGrade}</td>
              <td>{gradGrade}</td>
              <td>{sayCount}</td>
              <td>{eaCount}</td>
              <td>{sozCount}</td>
              <td>{dilCount}</td>
              <td style={{ textAlign: "center" }}>
                <Button onClick={() => navigate("ogrenci-ekle")} size="sm" >Öğrenci Ekle</Button>
              </td>
            </tr>

          </tbody>
        </Table> */}

        {
          students
            ? students.length > 0
              ? <Table style={{ marginTop: '8px' }} striped bordered responsive>
                <thead>
                  <tr>
                    <th style={{ width: "1%" }}>#</th>
                    <th style={{ width: "20%" }}>Ad</th>
                    <th style={{ width: "20%" }}>Soyad</th>
                    <th style={{ width: "15%" }}>Öğrenci No</th>
                    <th style={{ width: "15%" }}>Sınıf & Şube</th>
                    <th style={{ width: "15%" }}>Alan</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    students && students.map((student, index) => {
                      return (
                        <tr key={index} style={{ verticalAlign: "middle" }}>
                          <td>{index + 1}</td>
                          <td>{student.name}</td>
                          <td>{student.surname}</td>
                          <td>{student.studentNo}</td>
                          <td>{student.grade} {student.classCode ? `- ${student.classCode}` : null}</td>
                          <td>{student.domain} {student.lang ? `(${student.lang[0].toUpperCase()}${student.lang[1].toUpperCase()}${student.lang[2].toUpperCase()})` : ""}</td>
                          <td style={{ textAlign: "center" }}>
                            <Button onClick={() => navigate(student.uid)} size="sm">Seç</Button>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </Table>
              : null
            : null
        }


      </Container>
    </>
  )
}
