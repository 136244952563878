import React, { useEffect, useState } from 'react'
import { Navbar, Container, Button, Spinner, Row, Col, Card, Form, FloatingLabel, Modal } from 'react-bootstrap'
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

export default function AddNewExam() {

  const [show, setShow] = useState(false);
  const [modalError, setModalError] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [clicked, setClicked] = useState(false)

  const { createNewExam, userData } = useAuth();

  const navigate = useNavigate()

  const handleFocus = (event) => event.target.select();

  const handleClick = async () => {
    setClicked(true)
    if (userData && name && type) {
      if (type === "grade") {
        if (grade && questionNumber && turkishTrue && turkishFalse && turkishEmpty && socialTrue && socialFalse && socialEmpty && mathTrue && mathFalse && mathEmpty && scienceTrue && scienceFalse && scienceEmpty) {
          if (Number(questionNumber) === (Number(turkishTrue) + Number(turkishFalse) + Number(turkishEmpty) + Number(socialTrue) + Number(socialFalse) + Number(socialEmpty) + Number(mathTrue) + Number(mathFalse) + Number(mathEmpty) + Number(scienceTrue) + Number(scienceFalse) + Number(scienceEmpty))) {
            const turkishNet = Number(turkishTrue) - (Number(turkishFalse) * 0.25)
            const socialNet = Number(socialTrue) - (Number(socialFalse) * 0.25)
            const mathNet = Number(mathTrue) - (Number(mathFalse) * 0.25)
            const scienceNet = Number(scienceTrue) - (Number(scienceFalse) * 0.25)
            const totalNet = Number(turkishNet) + Number(socialNet) + Number(mathNet) + Number(scienceNet)

            try {
              await createNewExam({
                studentId: userData.uid,
                name: name.trim(),
                type: type,
                grade: grade,
                questionNumber: Number(questionNumber),
                turkishTrue: Number(turkishTrue),
                turkishFalse: Number(turkishFalse),
                turkishEmpty: Number(turkishEmpty),
                socialTrue: Number(socialTrue),
                socialFalse: Number(socialFalse),
                socialEmpty: Number(socialEmpty),
                mathTrue: Number(mathTrue),
                mathFalse: Number(mathFalse),
                mathEmpty: Number(mathEmpty),
                scienceTrue: Number(scienceTrue),
                scienceFalse: Number(scienceFalse),
                scienceEmpty: Number(scienceEmpty),
                turkishNet: Number(turkishNet),
                socialNet: Number(socialNet),
                mathNet: Number(mathNet),
                scienceNet: Number(scienceNet),
                totalNet: Number(totalNet)
              })
              setClicked(false)
              navigate("/ogrenci-paneli/denemeler")
            } catch (error) {
              setClicked(false)
              // alert("Bir sorun oluştu. Lütfen tekrar deneyin.")
              setModalError("Bir sorun oluştu. Lütfen tekrar deneyin.")
              handleShow()
            }
          } else {
            setClicked(false)
            setModalError("Girmiş olduğunuz doğru yanlış ve boş sayıları toplamı deneme sınavının soru sayısına eşit değildir. Lütfen kontrol ediniz.")
            handleShow()
          }
        } else {
          setClicked(false)
          setModalError("Lütfen tüm alanları tam ve doğru doldurun.")
          handleShow()
        }
      }
      else if (type === "branch") {
        if (questionNumber2 && examType && lesson && generalTrue && generalFalse && generalEmpty) {
          if (Number(questionNumber2) === (Number(generalTrue) + Number(generalFalse) + Number(generalEmpty))) {
            const totalNet = Number(generalTrue) - (Number(generalFalse) * 0.25)

            try {
              await createNewExam({
                studentId: userData.uid,
                name: name.trim(),
                type: type,
                examType: examType,
                lesson: lesson,
                questionNumber: Number(questionNumber2),
                generalTrue: Number(generalTrue),
                generalFalse: Number(generalFalse),
                generalEmpty: Number(generalEmpty),
                totalNet: Number(totalNet)
              })
              setClicked(false)
              navigate("/ogrenci-paneli/denemeler")
            } catch (error) {
              setClicked(false)
              // alert("Bir sorun oluştu. Lütfen tekrar deneyin.")
              setModalError("Bir sorun oluştu. Lütfen tekrar deneyin.")
              handleShow()
            }
          } else {
            setClicked(false)
            setModalError("Girmiş olduğunuz doğru yanlış ve boş sayıları toplamı deneme sınavının soru sayısına eşit değildir. Lütfen kontrol ediniz.")
            handleShow()
          }
        } else {
          setClicked(false)
          setModalError("Lütfen tüm alanları tam ve doğru doldurun.")
          handleShow()
        }
      }
      else if (type === "tyt") {
        if (questionNumber3 && turkishTrue2 && turkishFalse2 && turkishEmpty && socialTrue2 && socialFalse2 && socialEmpty2 && mathTrue2 && mathFalse2 && mathEmpty2 && scienceTrue2 && scienceFalse2 && scienceEmpty2) {
          if (Number(questionNumber3) === (Number(turkishTrue2) + Number(turkishFalse2) + Number(turkishEmpty2) + Number(socialTrue2) + Number(socialFalse2) + Number(socialEmpty2) + Number(mathTrue2) + Number(mathFalse2) + Number(mathEmpty2) + Number(scienceTrue2) + Number(scienceFalse2) + Number(scienceEmpty2))) {
            const turkishNet = Number(turkishTrue2) - (Number(turkishFalse2) * 0.25)
            const socialNet = Number(socialTrue2) - (Number(socialFalse2) * 0.25)
            const mathNet = Number(mathTrue2) - (Number(mathFalse2) * 0.25)
            const scienceNet = Number(scienceTrue2) - (Number(scienceFalse2) * 0.25)
            const totalNet = Number(turkishNet) + Number(socialNet) + Number(mathNet) + Number(scienceNet)

            try {
              await createNewExam({
                studentId: userData.uid,
                name: name.trim(),
                type: type,
                questionNumber: questionNumber3,
                turkishTrue: turkishTrue2,
                turkishFalse: turkishFalse2,
                turkishEmpty: turkishEmpty2,
                socialTrue: socialTrue2,
                socialFalse: socialFalse2,
                socialEmpty: socialEmpty2,
                mathTrue: mathTrue2,
                mathFalse: mathFalse2,
                mathEmpty: mathEmpty2,
                scienceTrue: scienceTrue2,
                scienceFalse: scienceFalse2,
                scienceEmpty: scienceEmpty2,
                turkishNet: turkishNet,
                socialNet: socialNet,
                mathNet: mathNet,
                scienceNet: scienceNet,
                totalNet: totalNet
              })
              setClicked(false)
              navigate("/ogrenci-paneli/denemeler")
            } catch (error) {
              setClicked(false)
              // alert("Bir sorun oluştu. Lütfen tekrar deneyin.")
              setModalError("Bir sorun oluştu. Lütfen tekrar deneyin.")
              handleShow()
            }
          } else {
            setClicked(false)
            setModalError("Girmiş olduğunuz doğru yanlış ve boş sayıları toplamı deneme sınavının soru sayısına eşit değildir. Lütfen kontrol ediniz.")
            handleShow()
          }
        } else {
          setClicked(false)
          setModalError("Lütfen tüm alanları tam ve doğru doldurun.")
          handleShow()
        }
      }
      else if (type === "ayt") {
        if (domain === "SAY") {
          if (questionNumber4 && mathTrue3 && mathFalse3 && mathEmpty3 && physicsTrue3 && physicsFalse3 && physicsEmpty3 && chemistryTrue3 && chemistryFalse3 && chemistryEmpty3 && biologyTrue3 && biologyFalse3 && biologyEmpty3) {
            if (Number(questionNumber4) === (Number(mathTrue3) + Number(mathFalse3) + Number(mathEmpty3) + Number(physicsTrue3) + Number(physicsFalse3) + Number(physicsEmpty3) + Number(chemistryTrue3) + Number(chemistryFalse3) + Number(chemistryEmpty3) + Number(biologyTrue3) + Number(biologyFalse3) + Number(biologyEmpty3))) {
              const mathNet = Number(mathTrue3) - (Number(mathFalse3) * 0.25)
              const physicsNet = Number(physicsTrue3) - (Number(physicsFalse3) * 0.25)
              const chemistryNet = Number(chemistryTrue3) - (Number(chemistryFalse3) * 0.25)
              const biologyNet = Number(biologyTrue3) - (Number(biologyFalse3) * 0.25)
              const totalNet = Number(mathNet) + Number(physicsNet) + Number(chemistryNet) + Number(biologyNet)

              try {
                await createNewExam({
                  studentId: userData.uid,
                  name: name.trim(),
                  type: type,
                  domain: domain,
                  questionNumber: Number(questionNumber4),
                  mathTrue: Number(mathTrue3),
                  mathFalse: Number(mathFalse3),
                  mathEmpty: Number(mathEmpty3),
                  physicsTrue: Number(physicsTrue3),
                  physicsFalse: Number(physicsFalse3),
                  physicsEmpty: Number(physicsEmpty3),
                  chemistryTrue: Number(chemistryTrue3),
                  chemistryFalse: Number(chemistryFalse3),
                  chemistryEmpty: Number(chemistryEmpty3),
                  biologyTrue: Number(biologyTrue3),
                  biologyFalse: Number(biologyFalse3),
                  biologyEmpty: Number(biologyEmpty3),
                  mathNet: Number(mathNet),
                  physicsNet: Number(physicsNet),
                  chemistryNet: Number(chemistryNet),
                  biologyNet: Number(biologyNet),
                  totalNet: Number(totalNet)
                })
                setClicked(false)
                navigate("/ogrenci-paneli/denemeler")
              } catch (error) {
                setClicked(false)
                // alert("Bir sorun oluştu. Lütfen tekrar deneyin.")
                setModalError("Bir sorun oluştu. Lütfen tekrar deneyin.")
                handleShow()
              }
            } else {
              setClicked(false)
              setModalError("Girmiş olduğunuz doğru yanlış ve boş sayıları toplamı deneme sınavının soru sayısına eşit değildir. Lütfen kontrol ediniz.")
              handleShow()
            }
          } else {
            setClicked(false)
            setModalError("Lütfen tüm alanları tam ve doğru doldurun.")
            handleShow()
          }
        } else if (domain === "EA") {
          if (questionNumber4 && mathTrue4 && mathFalse4 && mathEmpty4 && edebiyatTrue4 && edebiyatFalse4 && edebiyatEmpty4 && tarihTrue4 && tarihFalse4 && tarihEmpty4 && cografyaTrue4 && cografyaFalse4 && cografyaEmpty4) {
            if (Number(questionNumber4) === (Number(mathTrue4) + Number(mathFalse4) + Number(mathEmpty4) + Number(edebiyatTrue4) + Number(edebiyatFalse4) + Number(edebiyatEmpty4) + Number(tarihTrue4) + Number(tarihFalse4) + Number(tarihEmpty4) + Number(cografyaTrue4) + Number(cografyaFalse4) + Number(cografyaEmpty4))) {
              const mathNet = Number(mathTrue4) - (Number(mathFalse4) * 0.25)
              const edebiyatNet = Number(edebiyatTrue4) - (Number(edebiyatFalse4) * 0.25)
              const tarihNet = Number(tarihTrue4) - (Number(tarihFalse4) * 0.25)
              const cografyaNet = Number(cografyaTrue4) - (Number(cografyaFalse4) * 0.25)
              const totalNet = Number(mathNet) + Number(edebiyatNet) + Number(tarihNet) + Number(cografyaNet)

              try {
                await createNewExam({
                  studentId: userData.uid,
                  name: name.trim(),
                  type: type,
                  domain: domain,
                  questionNumber: Number(questionNumber4),
                  mathTrue: Number(mathTrue4),
                  mathFalse: Number(mathFalse4),
                  mathEmpty: Number(mathEmpty4),
                  edebiyatTrue: Number(edebiyatTrue4),
                  edebiyatFalse: Number(edebiyatFalse4),
                  edebiyatEmpty: Number(edebiyatEmpty4),
                  tarihTrue: Number(tarihTrue4),
                  tarihFalse: Number(tarihFalse4),
                  tarihEmpty: Number(tarihEmpty4),
                  cografyaTrue: Number(cografyaTrue4),
                  cografyaFalse: Number(cografyaFalse4),
                  cografyaEmpty: Number(cografyaEmpty4),
                  mathNet: Number(mathNet),
                  edebiyatNet: Number(edebiyatNet),
                  tarihNet: Number(tarihNet),
                  cografyaNet: Number(cografyaNet),
                  totalNet: Number(totalNet)
                })
                setClicked(false)
                navigate("/ogrenci-paneli/denemeler")
              } catch (error) {
                setClicked(false)
                // alert("Bir sorun oluştu. Lütfen tekrar deneyin.")
                setModalError("Bir sorun oluştu. Lütfen tekrar deneyin.")
                handleShow()
              }
            } else {
              setClicked(false)
              setModalError("Girmiş olduğunuz doğru yanlış ve boş sayıları toplamı deneme sınavının soru sayısına eşit değildir. Lütfen kontrol ediniz.")
              handleShow()
            }
          } else {
            setClicked(false)
            setModalError("Lütfen tüm alanları tam ve doğru doldurun.")
            handleShow()
          }
        } else if (domain === "SÖZ") {
          if (questionNumber4 && edebiyatTrue5 && edebiyatFalse5 && edebiyatEmpty5 && tarih1True5 && tarih1False5 && tarih1Empty5 && tarihTrue5 && tarihFalse5 && tarihEmpty5 && cografya1True5 && cografya1False5 && cografya1Empty5 && cografyaTrue5 && cografyaFalse5 && cografyaEmpty5 && felsefeTrue5 && felsefeFalse5 && felsefeEmpty5 && dinTrue5 && dinFalse5 && dinEmpty5) {
            if (Number(questionNumber4) === (Number(edebiyatTrue5) + Number(edebiyatFalse5) + Number(edebiyatEmpty5) + Number(tarih1True5) + Number(tarih1False5) + Number(tarih1Empty5) + Number(tarihTrue5) + Number(tarihFalse5) + Number(tarihEmpty5) + Number(cografya1True5) + Number(cografya1False5) + Number(cografya1Empty5) + Number(cografyaTrue5) + Number(cografyaFalse5) + Number(cografyaEmpty5) + Number(felsefeTrue5) + Number(felsefeFalse5) + Number(felsefeEmpty5) + Number(dinTrue5) + Number(dinFalse5) + Number(dinEmpty5))) {

              const edebiyatNet = Number(edebiyatTrue5) - (Number(edebiyatFalse5) * 0.25)
              const tarih1Net = Number(tarih1True5) - (Number(tarih1False5) * 0.25)
              const tarih2Net = Number(tarihTrue5) - (Number(tarihFalse5) * 0.25)
              const cografya1Net = Number(cografya1True5) - (Number(cografya1False5) * 0.25)
              const cografya2Net = Number(cografyaTrue5) - (Number(cografyaFalse5) * 0.25)
              const felsefeNet = Number(felsefeTrue5) - (Number(felsefeFalse5) * 0.25)
              const dinNet = Number(dinTrue5) - (Number(dinFalse5) * 0.25)
              const totalNet = Number(edebiyatNet) + Number(tarih1Net) + Number(cografya1Net) + Number(tarih2Net) + Number(cografya2Net) + Number(felsefeNet) + Number(dinNet)

              try {
                await createNewExam({
                  studentId: userData.uid,
                  name: name.trim(),
                  type: type,
                  domain: domain,
                  questionNumber: Number(questionNumber4),
                  edebiyatTrue: Number(edebiyatTrue5),
                  edebiyatFalse: Number(edebiyatFalse5),
                  edebiyatEmpty: Number(edebiyatEmpty5),
                  tarih1True: Number(tarih1True5),
                  tarih1False: Number(tarih1False5),
                  tarih1Empty: Number(tarih1Empty5),
                  cografya1True: Number(cografya1True5),
                  cografya1False: Number(cografya1False5),
                  cografya1Empty: Number(cografya1Empty5),
                  tarih2True: Number(tarihTrue5),
                  tarih2False: Number(tarihFalse5),
                  tarih2Empty: Number(tarihEmpty5),
                  cografya2True: Number(cografyaTrue5),
                  cografya2False: Number(cografyaFalse5),
                  cografya2Empty: Number(cografyaEmpty5),
                  felsefeTrue: Number(felsefeTrue5),
                  felsefeFalse: Number(felsefeFalse5),
                  felsefeEmpty: Number(felsefeEmpty5),
                  dinTrue: Number(dinTrue5),
                  dinFalse: Number(dinFalse5),
                  dinEmpty: Number(dinEmpty5),
                  edebiyatNet: Number(edebiyatNet),
                  tarih1Net: Number(tarih1Net),
                  tarih2Net: Number(tarih2Net),
                  cografya1Net: Number(cografya1Net),
                  cografya2Net: Number(cografya2Net),
                  felsefeNet: Number(felsefeNet),
                  dinNet: Number(dinNet),
                  totalNet: Number(totalNet)
                })
                setClicked(false)
                navigate("/ogrenci-paneli/denemeler")
              } catch (error) {
                console.log(error);
                setClicked(false)
                // alert("Bir sorun oluştu. Lütfen tekrar deneyin.")
                setModalError("Bir sorun oluştu. Lütfen tekrar deneyin.")
                handleShow()
              }
            } else {
              setClicked(false)
              setModalError("Girmiş olduğunuz doğru yanlış ve boş sayıları toplamı deneme sınavının soru sayısına eşit değildir. Lütfen kontrol ediniz.")
              handleShow()
            }
          } else {
            setClicked(false)
            setModalError("Lütfen tüm alanları tam ve doğru doldurun.")
            handleShow()
          }
        }
      }
      else if (type === "ydt") {
        if (questionNumber5 && lang && langTrue && langFalse && langEmpty) {
          if (Number(questionNumber5) === (Number(langTrue) + Number(langFalse) + Number(langEmpty))) {
            const totalNet = Number(langTrue) - (Number(langFalse) * 0.25)
            try {
              await createNewExam({
                studentId: userData.uid,
                name: name.trim(),
                type: type,
                lang: lang,
                questionNumber: Number(questionNumber5),
                langTrue: Number(langTrue),
                langFalse: Number(langFalse),
                langEmpty: Number(langEmpty),
                totalNet: Number(totalNet)
              })
              setClicked(false)
              navigate("/ogrenci-paneli/denemeler")
            } catch (error) {
              setClicked(false)
              // alert("Bir sorun oluştu. Lütfen tekrar deneyin.")
              setModalError("Bir sorun oluştu. Lütfen tekrar deneyin.")
              handleShow()
            }
          } else {
            setClicked(false)
            setModalError("Girmiş olduğunuz doğru yanlış ve boş sayıları toplamı deneme sınavının soru sayısına eşit değildir. Lütfen kontrol ediniz.")
            handleShow()
          }
        } else {
          setClicked(false)
          setModalError("Lütfen tüm alanları tam ve doğru doldurun.")
          handleShow()
        }
      }
    } else {
      setClicked(false)
      // alert("Lütfen tüm alanları tam ve doğru doldurun.")
      setModalError("Lütfen tüm alanları tam ve doğru doldurun.")
      handleShow()
    }
  }

  const [name, setName] = useState("")
  const [type, setType] = useState("grade")

  // sınıf denemesi ise
  const [grade, setGrade] = useState("9")
  const [questionNumber, setQuestionNumber] = useState("120")

  const [turkishTrue, setTurkishTrue] = useState("0")
  const [turkishFalse, setTurkishFalse] = useState("0")
  const [turkishEmpty, setTurkishEmpty] = useState("0")

  const [socialTrue, setSocialTrue] = useState("0")
  const [socialFalse, setSocialFalse] = useState("0")
  const [socialEmpty, setSocialEmpty] = useState("0")

  const [mathTrue, setMathTrue] = useState("0")
  const [mathFalse, setMathFalse] = useState("0")
  const [mathEmpty, setMathEmpty] = useState("0")

  const [scienceTrue, setScienceTrue] = useState("0")
  const [scienceFalse, setScienceFalse] = useState("0")
  const [scienceEmpty, setScienceEmpty] = useState("0")

  // branş denemesi ise
  const [examType, setExamType] = useState("TYT")
  const [lesson, setLesson] = useState("Türkçe")
  const [questionNumber2, setQuestionNumber2] = useState("0")
  const [generalTrue, setGeneralTrue] = useState("0")
  const [generalFalse, setGeneralFalse] = useState("0")
  const [generalEmpty, setGeneralEmpty] = useState("0")

  // tyt denemesi ise
  const [questionNumber3, setQuestionNumber3] = useState("120")

  const [turkishTrue2, setTurkishTrue2] = useState("0")
  const [turkishFalse2, setTurkishFalse2] = useState("0")
  const [turkishEmpty2, setTurkishEmpty2] = useState("0")

  const [socialTrue2, setSocialTrue2] = useState("0")
  const [socialFalse2, setSocialFalse2] = useState("0")
  const [socialEmpty2, setSocialEmpty2] = useState("0")

  const [mathTrue2, setMathTrue2] = useState("0")
  const [mathFalse2, setMathFalse2] = useState("0")
  const [mathEmpty2, setMathEmpty2] = useState("0")

  const [scienceTrue2, setScienceTrue2] = useState("0")
  const [scienceFalse2, setScienceFalse2] = useState("0")
  const [scienceEmpty2, setScienceEmpty2] = useState("0")

  // ayt denemesi SAY ise
  const [questionNumber4, setQuestionNumber4] = useState("80")
  const [domain, setDomain] = useState("SAY")

  const [mathTrue3, setMathTrue3] = useState("0")
  const [mathFalse3, setMathFalse3] = useState("0")
  const [mathEmpty3, setMathEmpty3] = useState("0")

  const [physicsTrue3, setPhysicsTrue3] = useState("0")
  const [physicsFalse3, setPhysicsFalse3] = useState("0")
  const [physicsEmpty3, setPhysicsEmpty3] = useState("0")

  const [chemistryTrue3, setChemistryTrue3] = useState("0")
  const [chemistryFalse3, setChemistryFalse3] = useState("0")
  const [chemistryEmpty3, setChemistryEmpty3] = useState("0")

  const [biologyTrue3, setBiologyTrue3] = useState("0")
  const [biologyFalse3, setBiologyFalse3] = useState("0")
  const [biologyEmpty3, setBiologyEmpty3] = useState("0")

  // ayt denemesi EA ise
  const [mathTrue4, setMathTrue4] = useState("0")
  const [mathFalse4, setMathFalse4] = useState("0")
  const [mathEmpty4, setMathEmpty4] = useState("0")

  const [edebiyatTrue4, setEdebiyatTrue4] = useState("0")
  const [edebiyatFalse4, setEdebiyatFalse4] = useState("0")
  const [edebiyatEmpty4, setEdebiyatEmpty4] = useState("0")

  const [tarihTrue4, setTarihTrue4] = useState("0")
  const [tarihFalse4, setTarihFalse4] = useState("0")
  const [tarihEmpty4, setTarihEmpty4] = useState("0")

  const [cografyaTrue4, setCografyaTrue4] = useState("0")
  const [cografyaFalse4, setCografyaFalse4] = useState("0")
  const [cografyaEmpty4, setCografyaEmpty4] = useState("0")

  // ayt denemesi SÖZ ise


  const [edebiyatTrue5, setEdebiyatTrue5] = useState("0")
  const [edebiyatFalse5, setEdebiyatFalse5] = useState("0")
  const [edebiyatEmpty5, setEdebiyatEmpty5] = useState("0")

  const [tarih1True5, setTarih1True5] = useState("0")
  const [tarih1False5, setTarih1False5] = useState("0")
  const [tarih1Empty5, setTarih1Empty5] = useState("0")

  const [cografya1True5, setCografya1True5] = useState("0")
  const [cografya1False5, setCografya1False5] = useState("0")
  const [cografya1Empty5, setCografya1Empty5] = useState("0")

  const [tarihTrue5, setTarihTrue5] = useState("0")
  const [tarihFalse5, setTarihFalse5] = useState("0")
  const [tarihEmpty5, setTarihEmpty5] = useState("0")

  const [cografyaTrue5, setCografyaTrue5] = useState("0")
  const [cografyaFalse5, setCografyaFalse5] = useState("0")
  const [cografyaEmpty5, setCografyaEmpty5] = useState("0")

  const [felsefeTrue5, setFelsefeTrue5] = useState("0")
  const [felsefeFalse5, setFelsefeFalse5] = useState("0")
  const [felsefeEmpty5, setFelsefeEmpty5] = useState("0")

  const [dinTrue5, setDinTrue5] = useState("0")
  const [dinFalse5, setDinFalse5] = useState("0")
  const [dinEmpty5, setDinEmpty5] = useState("0")

  // ydt denemesi ise
  const [questionNumber5, setQuestionNumber5] = useState("80")
  const [lang, setLang] = useState("İngilizce")

  const [langTrue, setLangTrue] = useState("0")
  const [langFalse, setLangFalse] = useState("0")
  const [langEmpty, setLangEmpty] = useState("0")

  useEffect(() => {
    if (lesson) {
      if (examType === "TYT") {
        switch (lesson) {
          case "Türkçe":
            setQuestionNumber2("40")
            break;
          case "Tarih":
            setQuestionNumber2("5")
            break;
          case "Coğrafya":
            setQuestionNumber2("5")
            break;
          case "Felsefe":
            setQuestionNumber2("5")
            break;
          case "Din Kültürü":
            setQuestionNumber2("5")
            break;
          case "Matematik":
            setQuestionNumber2("40")
            break;
          case "Geometri":
            setQuestionNumber2("10")
            break;
          case "Fizik":
            setQuestionNumber2("7")
            break;
          case "Kimya":
            setQuestionNumber2("7")
            break;
          case "Biyoloji":
            setQuestionNumber2("6")
            break;
          default:
            break;
        }
      }
      else if (examType === "AYT") {
        switch (lesson) {
          case "Edebiyat":
            setQuestionNumber2("24")
            break;
          case "Tarih 1":
            setQuestionNumber2("10")
            break;
          case "Tarih 2":
            setQuestionNumber2("11")
            break;
          case "Coğrafya 1":
            setQuestionNumber2("6")
            break;
          case "Coğrafya 2":
            setQuestionNumber2("11")
            break;
          case "Felsefe":
            setQuestionNumber2("12")
            break;
          case "Din Kültürü":
            setQuestionNumber2("6")
            break;
          case "Matematik":
            setQuestionNumber2("40")
            break;
          case "Geometri":
            setQuestionNumber2("10")
            break;
          case "Fizik":
            setQuestionNumber2("14")
            break;
          case "Kimya":
            setQuestionNumber2("13")
            break;
          case "Biyoloji":
            setQuestionNumber2("13")
            break;
          default:
            break;
        }
      }
    }
  }, [lesson, examType])

  return (
    <>
      <Navbar style={{ marginBottom: "24px" }} expand="lg" className="bg-white border-bottom">
        <Container>
          <Navbar.Brand>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {/* tıklanamayan */}
              <p style={{ margin: 0, color: "#000000A6", fontSize: "0.9rem", textDecoration: "none", cursor: "default" }}>Aktiviteler</p>

              {/* çizgi */}
              <p style={{ margin: "0 6px", color: "#000000A6", fontSize: "0.9rem" }} >/</p>

              <a href='/ogrenci-paneli/denemeler' style={{ margin: 0, fontSize: "0.9rem", fontWeight: "400", color: "#000000A6", textDecoration: "none" }} >Denemeler</a>

              <p style={{ margin: "0 6px", color: "#000000A6", fontSize: "0.9rem" }} >/</p>

              <p style={{ margin: 0, color: "#000000", fontSize: "0.9rem", textDecoration: "none", cursor: "default", fontWeight: "500" }}>Deneme Ekle</p>

              {/* tıklanabilir */}
              {/* <a href='/kurum-paneli/ogrenci-listesi/ogrenci-ekle' style={{ margin: 0, fontSize: "1rem", fontWeight: "500", color: "#000000", textDecoration: "none" }} >Öğrenci Ekle</a> */}
            </div>
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Container style={{ marginBottom: "24px" }}>
        <Container style={{ display: "flex", justifyContent: "center", alignItems: "flex-start" }}>
          <Row style={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <Col lg={3} md={2} sm={1} />
            <Col lg={6} md={8} sm={10} style={{ padding: "0", maxWidth: "450px" }}>
              <Card >
                <Card.Body>
                  <Form onSubmit={e => { e.preventDefault(); }}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <FloatingLabel
                        controlId="floatingInput1"
                        label="Deneme Adı *"
                        className="mb-3"
                      >
                        <Form.Control type='text' placeholder="Ad*" value={name} onChange={(e) => setName(e.target.value)} />
                      </FloatingLabel>
                    </Form.Group>

                    <FloatingLabel controlId="floatingSelect" label="Deneme Türü *">
                      <Form.Select className="mb-3" value={type} onChange={(e) => setType(e.target.value)} aria-label="Default select example">
                        <option value="grade">Sınıf Denemesi</option>
                        <option value="branch">Branş Denemesi</option>
                        <option value="tyt">TYT Denemesi</option>
                        <option value="ayt">AYT Denemesi</option>
                        <option value="ydt">YDT Denemesi</option>
                      </Form.Select>
                    </FloatingLabel>

                    {
                      (type === "grade") &&
                      <>
                        <FloatingLabel controlId="floatingSelect" label="Sınıf *">
                          <Form.Select className="mb-3" value={grade} onChange={(e) => setGrade(e.target.value)} aria-label="Default select example">
                            <option value="9">9. Sınıf</option>
                            <option value="10">10. Sınıf</option>
                            <option value="11">11. Sınıf</option>
                            <option value="12">12. Sınıf</option>
                          </Form.Select>
                        </FloatingLabel>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <FloatingLabel
                            controlId="floatingInput1"
                            label="Soru Sayısı *"
                            className="mb-3"
                          >
                            <Form.Control type='number' placeholder="" value={questionNumber} onChange={(e) => setQuestionNumber(e.target.value)} />
                          </FloatingLabel>
                        </Form.Group>

                        <div style={{ padding: "6px 12px 6px 12px", marginBottom: "6px" }}>
                          <Row className="mb-3" >
                            <Col></Col>
                            <Col style={{ textAlign: "center", fontWeight: "500", textDecoration: "underline", fontSize: "0.9rem" }} >Doğru</Col>
                            <Col style={{ textAlign: "center", fontWeight: "500", textDecoration: "underline", fontSize: "0.9rem" }}>Yanlış</Col>
                            <Col style={{ textAlign: "center", fontWeight: "500", textDecoration: "underline", fontSize: "0.9rem" }}>Boş</Col>
                          </Row>
                          <Row className="mb-3" style={{ display: "flex", alignItems: "center" }} >
                            <Col style={{ textAlign: "start", fontWeight: "500", fontSize: "0.9rem" }}>Türkçe:</Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={turkishTrue} onChange={(e) => setTurkishTrue(e.target.value)} />
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={turkishFalse} onChange={(e) => setTurkishFalse(e.target.value)} />
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={turkishEmpty} onChange={(e) => setTurkishEmpty(e.target.value)} />
                            </Col>
                          </Row>
                          <Row className="mb-3" style={{ display: "flex", alignItems: "center" }} >
                            <Col style={{ textAlign: "start", fontWeight: "500", fontSize: "0.9rem" }}>Sosyal:</Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={socialTrue} onChange={(e) => setSocialTrue(e.target.value)} />
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={socialFalse} onChange={(e) => setSocialFalse(e.target.value)} />
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={socialEmpty} onChange={(e) => setSocialEmpty(e.target.value)} />
                            </Col>
                          </Row>
                          <Row className="mb-3" style={{ display: "flex", alignItems: "center" }} >
                            <Col style={{ textAlign: "start", fontWeight: "500", fontSize: "0.9rem" }}>Mat:</Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={mathTrue} onChange={(e) => setMathTrue(e.target.value)} />
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={mathFalse} onChange={(e) => setMathFalse(e.target.value)} />
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={mathEmpty} onChange={(e) => setMathEmpty(e.target.value)} />
                            </Col>
                          </Row>
                          <Row className="mb-3" style={{ display: "flex", alignItems: "center" }} >
                            <Col style={{ textAlign: "start", fontWeight: "500", fontSize: "0.9rem" }}>Fen:</Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={scienceTrue} onChange={(e) => setScienceTrue(e.target.value)} />
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={scienceFalse} onChange={(e) => setScienceFalse(e.target.value)} />
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={scienceEmpty} onChange={(e) => setScienceEmpty(e.target.value)} />
                            </Col>
                          </Row>
                        </div>
                      </>
                    }

                    {
                      (type === "branch") &&
                      <>
                        <FloatingLabel controlId="floatingSelect" label="Sınav Türü *">
                          <Form.Select className="mb-3" value={examType} onChange={(e) => setExamType(e.target.value)} aria-label="Default select example">
                            <option value="TYT">TYT</option>
                            <option value="AYT">AYT</option>
                          </Form.Select>
                        </FloatingLabel>

                        {
                          examType === "TYT" && <FloatingLabel controlId="floatingSelect" label="Ders *">
                            <Form.Select className="mb-3" value={lesson} onChange={(e) => setLesson(e.target.value)} aria-label="Default select example">
                              <option value="Türkçe">Türkçe</option>
                              <option value="Tarih">Tarih</option>
                              <option value="Coğrafya">Coğrafya</option>
                              <option value="Felsefe">Felsefe</option>
                              <option value="Din Kültürü">Din Kültürü</option>
                              <option value="Matematik">Matematik</option>
                              <option value="Geometri">Geometri</option>
                              <option value="Fizik">Fizik</option>
                              <option value="Kimya">Kimya</option>
                              <option value="Biyoloji">Biyoloji</option>
                            </Form.Select>
                          </FloatingLabel>
                        }

                        {
                          examType === "AYT" && <FloatingLabel controlId="floatingSelect" label="Ders *">
                            <Form.Select className="mb-3" value={lesson} onChange={(e) => setLesson(e.target.value)} aria-label="Default select example">
                              <option value="Matematik">Matematik</option>
                              <option value="Geometri">Geometri</option>
                              <option value="Fizik">Fizik</option>
                              <option value="Kimya">Kimya</option>
                              <option value="Biyoloji">Biyoloji</option>
                              <option value="Edebiyat">Edebiyat</option>
                              <option value="Tarih 1">Tarih 1</option>
                              <option value="Tarih 2">Tarih 2</option>
                              <option value="Coğrafya 1">Coğrafya 1</option>
                              <option value="Coğrafya 2">Coğrafya 2</option>
                              <option value="Felsefe">Felsefe</option>
                              <option value="Din Kültürü">Din Kültürü</option>
                            </Form.Select>
                          </FloatingLabel>
                        }

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <FloatingLabel
                            controlId="floatingInput1"
                            label="Soru Sayısı *"
                            className="mb-3"
                          >
                            <Form.Control type='number' placeholder="" value={questionNumber2} onChange={(e) => setQuestionNumber2(e.target.value)} />
                          </FloatingLabel>
                        </Form.Group>

                        <div style={{ padding: "6px 12px 6px 12px", marginBottom: "6px" }}>
                          <Row className="mb-3" >
                            <Col style={{ textAlign: "center", fontWeight: "500", textDecoration: "underline", fontSize: "0.9rem" }} >Doğru</Col>
                            <Col style={{ textAlign: "center", fontWeight: "500", textDecoration: "underline", fontSize: "0.9rem" }}>Yanlış</Col>
                            <Col style={{ textAlign: "center", fontWeight: "500", textDecoration: "underline", fontSize: "0.9rem" }}>Boş</Col>
                          </Row>
                          <Row className="mb-3" style={{ display: "flex", alignItems: "center" }} >
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={generalTrue} onChange={(e) => setGeneralTrue(e.target.value)} />
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={generalFalse} onChange={(e) => setGeneralFalse(e.target.value)} />
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={generalEmpty} onChange={(e) => setGeneralEmpty(e.target.value)} />
                            </Col>
                          </Row>
                        </div>
                      </>
                    }

                    {
                      (type === "tyt") &&
                      <>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <FloatingLabel
                            controlId="floatingInput1"
                            label="Soru Sayısı *"
                            className="mb-3"
                          >
                            <Form.Control disabled type='number' placeholder="" value={questionNumber3} onChange={(e) => setQuestionNumber3(e.target.value)} />
                          </FloatingLabel>
                        </Form.Group>

                        <div style={{ padding: "6px 12px 6px 12px", marginBottom: "6px" }}>
                          <Row className="mb-3" >
                            <Col></Col>
                            <Col style={{ textAlign: "center", fontWeight: "500", textDecoration: "underline", fontSize: "0.9rem" }} >Doğru</Col>
                            <Col style={{ textAlign: "center", fontWeight: "500", textDecoration: "underline", fontSize: "0.9rem" }}>Yanlış</Col>
                            <Col style={{ textAlign: "center", fontWeight: "500", textDecoration: "underline", fontSize: "0.9rem" }}>Boş</Col>
                          </Row>
                          <Row className="mb-3" style={{ display: "flex", alignItems: "center" }} >
                            <Col style={{ textAlign: "start", fontWeight: "500", fontSize: "0.9rem" }}>Türkçe:</Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={turkishTrue2} onChange={(e) => setTurkishTrue2(e.target.value)} />
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={turkishFalse2} onChange={(e) => setTurkishFalse2(e.target.value)} />
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={turkishEmpty2} onChange={(e) => setTurkishEmpty2(e.target.value)} />
                            </Col>
                          </Row>
                          <Row className="mb-3" style={{ display: "flex", alignItems: "center" }} >
                            <Col style={{ textAlign: "start", fontWeight: "500", fontSize: "0.9rem" }}>Sosyal:</Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={socialTrue2} onChange={(e) => setSocialTrue2(e.target.value)} />
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={socialFalse2} onChange={(e) => setSocialFalse2(e.target.value)} />
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={socialEmpty2} onChange={(e) => setSocialEmpty2(e.target.value)} />
                            </Col>
                          </Row>
                          <Row className="mb-3" style={{ display: "flex", alignItems: "center" }} >
                            <Col style={{ textAlign: "start", fontWeight: "500", fontSize: "0.9rem" }}>Mat:</Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={mathTrue2} onChange={(e) => setMathTrue2(e.target.value)} />
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={mathFalse2} onChange={(e) => setMathFalse2(e.target.value)} />
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={mathEmpty2} onChange={(e) => setMathEmpty2(e.target.value)} />
                            </Col>
                          </Row>
                          <Row className="mb-3" style={{ display: "flex", alignItems: "center" }} >
                            <Col style={{ textAlign: "start", fontWeight: "500", fontSize: "0.9rem" }}>Fen:</Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={scienceTrue2} onChange={(e) => setScienceTrue2(e.target.value)} />
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={scienceFalse2} onChange={(e) => setScienceFalse2(e.target.value)} />
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={scienceEmpty2} onChange={(e) => setScienceEmpty2(e.target.value)} />
                            </Col>
                          </Row>
                        </div>
                      </>
                    }

                    {
                      (type === "ayt") &&
                      <>
                        <FloatingLabel controlId="floatingSelect" label="Alan *">
                          <Form.Select className="mb-3" value={domain} onChange={(e) => setDomain(e.target.value)} aria-label="Default select example">
                            <option value="SAY">SAY</option>
                            <option value="EA">EA</option>
                            <option value="SÖZ">SÖZ</option>
                          </Form.Select>
                        </FloatingLabel>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <FloatingLabel
                            controlId="floatingInput1"
                            label="Soru Sayısı *"
                            className="mb-3"
                          >
                            <Form.Control disabled type='number' placeholder="" value={questionNumber4} onChange={(e) => setQuestionNumber4(e.target.value)} />
                          </FloatingLabel>
                        </Form.Group>

                        {
                          domain === "SAY" && <div style={{ padding: "6px 12px 6px 12px", marginBottom: "6px" }}>
                            <Row className="mb-3" >
                              <Col></Col>
                              <Col style={{ textAlign: "center", fontWeight: "500", textDecoration: "underline", fontSize: "0.9rem" }} >Doğru</Col>
                              <Col style={{ textAlign: "center", fontWeight: "500", textDecoration: "underline", fontSize: "0.9rem" }}>Yanlış</Col>
                              <Col style={{ textAlign: "center", fontWeight: "500", textDecoration: "underline", fontSize: "0.9rem" }}>Boş</Col>
                            </Row>
                            <Row className="mb-3" style={{ display: "flex", alignItems: "center" }} >
                              <Col style={{ textAlign: "start", fontWeight: "500", fontSize: "0.9rem" }}>Mat:</Col>
                              <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={mathTrue3} onChange={(e) => setMathTrue3(e.target.value)} />
                              </Col>
                              <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={mathFalse3} onChange={(e) => setMathFalse3(e.target.value)} />
                              </Col>
                              <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={mathEmpty3} onChange={(e) => setMathEmpty3(e.target.value)} />
                              </Col>
                            </Row>
                            <Row className="mb-3" style={{ display: "flex", alignItems: "center" }} >
                              <Col style={{ textAlign: "start", fontWeight: "500", fontSize: "0.9rem" }}>Fizik:</Col>
                              <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={physicsTrue3} onChange={(e) => setPhysicsTrue3(e.target.value)} />
                              </Col>
                              <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={physicsFalse3} onChange={(e) => setPhysicsFalse3(e.target.value)} />
                              </Col>
                              <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={physicsEmpty3} onChange={(e) => setPhysicsEmpty3(e.target.value)} />
                              </Col>
                            </Row>
                            <Row className="mb-3" style={{ display: "flex", alignItems: "center" }} >
                              <Col style={{ textAlign: "start", fontWeight: "500", fontSize: "0.9rem" }}>Kimya:</Col>
                              <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={chemistryTrue3} onChange={(e) => setChemistryTrue3(e.target.value)} />
                              </Col>
                              <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={chemistryFalse3} onChange={(e) => setChemistryFalse3(e.target.value)} />
                              </Col>
                              <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={chemistryEmpty3} onChange={(e) => setChemistryEmpty3(e.target.value)} />
                              </Col>
                            </Row>
                            <Row className="mb-3" style={{ display: "flex", alignItems: "center" }} >
                              <Col style={{ textAlign: "start", fontWeight: "500", fontSize: "0.9rem" }}>Biyoloji:</Col>
                              <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={biologyTrue3} onChange={(e) => setBiologyTrue3(e.target.value)} />
                              </Col>
                              <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={biologyFalse3} onChange={(e) => setBiologyFalse3(e.target.value)} />
                              </Col>
                              <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={biologyEmpty3} onChange={(e) => setBiologyEmpty3(e.target.value)} />
                              </Col>
                            </Row>
                          </div>
                        }
                        {
                          domain === "EA" && <div style={{ padding: "6px 12px 6px 12px", marginBottom: "6px" }}>
                            <Row className="mb-3" >
                              <Col></Col>
                              <Col style={{ textAlign: "center", fontWeight: "500", textDecoration: "underline", fontSize: "0.9rem" }} >Doğru</Col>
                              <Col style={{ textAlign: "center", fontWeight: "500", textDecoration: "underline", fontSize: "0.9rem" }}>Yanlış</Col>
                              <Col style={{ textAlign: "center", fontWeight: "500", textDecoration: "underline", fontSize: "0.9rem" }}>Boş</Col>
                            </Row>
                            <Row className="mb-3" style={{ display: "flex", alignItems: "center" }} >
                              <Col style={{ textAlign: "start", fontWeight: "500", fontSize: "0.9rem" }}>Mat:</Col>
                              <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={mathTrue4} onChange={(e) => setMathTrue4(e.target.value)} />
                              </Col>
                              <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={mathFalse4} onChange={(e) => setMathFalse4(e.target.value)} />
                              </Col>
                              <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={mathEmpty4} onChange={(e) => setMathEmpty4(e.target.value)} />
                              </Col>
                            </Row>
                            <Row className="mb-3" style={{ display: "flex", alignItems: "center" }} >
                              <Col style={{ textAlign: "start", fontWeight: "500", fontSize: "0.9rem" }}>Edebiyat:</Col>
                              <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={edebiyatTrue4} onChange={(e) => setEdebiyatTrue4(e.target.value)} />
                              </Col>
                              <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={edebiyatFalse4} onChange={(e) => setEdebiyatFalse4(e.target.value)} />
                              </Col>
                              <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={edebiyatEmpty4} onChange={(e) => setEdebiyatEmpty4(e.target.value)} />
                              </Col>
                            </Row>
                            <Row className="mb-3" style={{ display: "flex", alignItems: "center" }} >
                              <Col style={{ textAlign: "start", fontWeight: "500", fontSize: "0.9rem" }}>Tarih:</Col>
                              <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={tarihTrue4} onChange={(e) => setTarihTrue4(e.target.value)} />
                              </Col>
                              <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={tarihFalse4} onChange={(e) => setTarihFalse4(e.target.value)} />
                              </Col>
                              <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={tarihEmpty4} onChange={(e) => setTarihEmpty4(e.target.value)} />
                              </Col>
                            </Row>
                            <Row className="mb-3" style={{ display: "flex", alignItems: "center" }} >
                              <Col style={{ textAlign: "start", fontWeight: "500", fontSize: "0.9rem" }}>Coğrafya:</Col>
                              <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={cografyaTrue4} onChange={(e) => setCografyaTrue4(e.target.value)} />
                              </Col>
                              <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={cografyaFalse4} onChange={(e) => setCografyaFalse4(e.target.value)} />
                              </Col>
                              <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={cografyaEmpty4} onChange={(e) => setCografyaEmpty4(e.target.value)} />
                              </Col>
                            </Row>
                          </div>
                        }
                        {
                          domain === "SÖZ" && <div style={{ padding: "6px 12px 6px 12px", marginBottom: "6px" }}>
                            <Row className="mb-3" >
                              <Col></Col>
                              <Col style={{ textAlign: "center", fontWeight: "500", textDecoration: "underline", fontSize: "0.9rem" }} >Doğru</Col>
                              <Col style={{ textAlign: "center", fontWeight: "500", textDecoration: "underline", fontSize: "0.9rem" }}>Yanlış</Col>
                              <Col style={{ textAlign: "center", fontWeight: "500", textDecoration: "underline", fontSize: "0.9rem" }}>Boş</Col>
                            </Row>
                            <Row className="mb-3" style={{ display: "flex", alignItems: "center" }} >
                              <Col style={{ textAlign: "start", fontWeight: "500", fontSize: "0.9rem" }}>Edebiyat:</Col>
                              <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={edebiyatTrue5} onChange={(e) => setEdebiyatTrue5(e.target.value)} />
                              </Col>
                              <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={edebiyatFalse5} onChange={(e) => setEdebiyatFalse5(e.target.value)} />
                              </Col>
                              <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={edebiyatEmpty5} onChange={(e) => setEdebiyatEmpty5(e.target.value)} />
                              </Col>
                            </Row>
                            <Row className="mb-3" style={{ display: "flex", alignItems: "center" }} >
                              <Col style={{ textAlign: "start", fontWeight: "500", fontSize: "0.9rem" }}>Tarih 1:</Col>
                              <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={tarih1True5} onChange={(e) => setTarih1True5(e.target.value)} />
                              </Col>
                              <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={tarih1False5} onChange={(e) => setTarih1False5(e.target.value)} />
                              </Col>
                              <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={tarih1Empty5} onChange={(e) => setTarih1Empty5(e.target.value)} />
                              </Col>
                            </Row>
                            <Row className="mb-3" style={{ display: "flex", alignItems: "center" }} >
                              <Col style={{ textAlign: "start", fontWeight: "500", fontSize: "0.9rem" }}>Coğrafya 1:</Col>
                              <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={cografya1True5} onChange={(e) => setCografya1True5(e.target.value)} />
                              </Col>
                              <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={cografya1False5} onChange={(e) => setCografya1False5(e.target.value)} />
                              </Col>
                              <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={cografya1Empty5} onChange={(e) => setCografya1Empty5(e.target.value)} />
                              </Col>
                            </Row>
                            <Row className="mb-3" style={{ display: "flex", alignItems: "center" }} >
                              <Col style={{ textAlign: "start", fontWeight: "500", fontSize: "0.9rem" }}>Tarih 2:</Col>
                              <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={tarihTrue5} onChange={(e) => setTarihTrue5(e.target.value)} />
                              </Col>
                              <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={tarihFalse5} onChange={(e) => setTarihFalse5(e.target.value)} />
                              </Col>
                              <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={tarihEmpty5} onChange={(e) => setTarihEmpty5(e.target.value)} />
                              </Col>
                            </Row>
                            <Row className="mb-3" style={{ display: "flex", alignItems: "center" }} >
                              <Col style={{ textAlign: "start", fontWeight: "500", fontSize: "0.9rem" }}>Coğrafya 2:</Col>
                              <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={cografyaTrue5} onChange={(e) => setCografyaTrue5(e.target.value)} />
                              </Col>
                              <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={cografyaFalse5} onChange={(e) => setCografyaFalse5(e.target.value)} />
                              </Col>
                              <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={cografyaEmpty5} onChange={(e) => setCografyaEmpty5(e.target.value)} />
                              </Col>
                            </Row>
                            <Row className="mb-3" style={{ display: "flex", alignItems: "center" }} >
                              <Col style={{ textAlign: "start", fontWeight: "500", fontSize: "0.9rem" }}>Felsefe:</Col>
                              <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={felsefeTrue5} onChange={(e) => setFelsefeTrue5(e.target.value)} />
                              </Col>
                              <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={felsefeFalse5} onChange={(e) => setFelsefeFalse5(e.target.value)} />
                              </Col>
                              <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={felsefeEmpty5} onChange={(e) => setFelsefeEmpty5(e.target.value)} />
                              </Col>
                            </Row>
                            <Row className="mb-3" style={{ display: "flex", alignItems: "center" }} >
                              <Col style={{ textAlign: "start", fontWeight: "500", fontSize: "0.9rem" }}>Din Kültürü:</Col>
                              <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={dinTrue5} onChange={(e) => setDinTrue5(e.target.value)} />
                              </Col>
                              <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={dinFalse5} onChange={(e) => setDinFalse5(e.target.value)} />
                              </Col>
                              <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={dinEmpty5} onChange={(e) => setDinEmpty5(e.target.value)} />
                              </Col>
                            </Row>
                          </div>
                        }
                      </>
                    }

                    {
                      (type === "ydt") &&
                      <>
                        <FloatingLabel controlId="floatingSelect" label="Alan *">
                          <Form.Select className="mb-3" value={lang} onChange={(e) => setLang(e.target.value)} aria-label="Default select example">
                            <option value="İngilizce">İngilizce</option>
                            <option value="Almanca">Almanca</option>
                          </Form.Select>
                        </FloatingLabel>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <FloatingLabel
                            controlId="floatingInput1"
                            label="Soru Sayısı *"
                            className="mb-3"
                          >
                            <Form.Control disabled type='number' placeholder="" value={questionNumber5} onChange={(e) => setQuestionNumber5(e.target.value)} />
                          </FloatingLabel>
                        </Form.Group>

                        <div style={{ padding: "6px 12px 6px 12px", marginBottom: "6px" }}>
                          <Row className="mb-3" >
                            <Col style={{ textAlign: "center", fontWeight: "500", textDecoration: "underline", fontSize: "0.9rem" }} >Doğru</Col>
                            <Col style={{ textAlign: "center", fontWeight: "500", textDecoration: "underline", fontSize: "0.9rem" }}>Yanlış</Col>
                            <Col style={{ textAlign: "center", fontWeight: "500", textDecoration: "underline", fontSize: "0.9rem" }}>Boş</Col>
                          </Row>
                          <Row className="mb-3" style={{ display: "flex", alignItems: "center" }} >
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={langTrue} onChange={(e) => setLangTrue(e.target.value)} />
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={langFalse} onChange={(e) => setLangFalse(e.target.value)} />
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                              <Form.Control onFocus={handleFocus} size="sm" style={{ width: "100%", textAlign: "center" }} type='number' value={langEmpty} onChange={(e) => setLangEmpty(e.target.value)} />
                            </Col>
                          </Row>
                        </div>
                      </>
                    }

                    <div style={{ margin: "0 0 12px 0", display: "flex", flexDirection: "row", justifyContent: "center", width: "100%", padding: 0 }}>
                      <Form.Text style={{ marginTop: 0, textAlign: "center" }} className="text-muted">
                        Yıldız (*) işareti bulunan alanları doldurmak zorunludur.
                      </Form.Text>
                    </div>

                    {
                      clicked
                        ? <Button style={{ width: "100%" }} variant="outline-secondary" disabled>
                          <Spinner
                            style={{ marginRight: "6px" }}
                            as="span"
                            size="sm"
                            aria-hidden="true"
                          />
                        </Button>
                        : <Button onClick={handleClick} style={{ width: "100%" }} variant="primary" >
                          Deneme Ekle
                        </Button>
                    }

                  </Form>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={3} md={2} sm={1} />
          </Row>
        </Container>

        <Modal
          show={show}
          onHide={handleClose}
          keyboard={false}
        >
          <Modal.Header closeButton>
            {/* <Modal.Title>{modalTitle}</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            {modalError}
          </Modal.Body>
        </Modal>
      </Container>
    </>
  )
}


