import { doc, updateDoc } from 'firebase/firestore';
import React, { useState } from 'react'
import { Navbar, Container, Accordion, Col, Row, Form, Modal, Button, } from 'react-bootstrap'
import { db } from '../../firebaseConfig';
import { useAuth } from '../../contexts/AuthContext';
import lessons from "../../datas/lessons.json"
import subjects from "../../datas/subjects.json"

export default function SubjectTrackingForStudent() {

  const { realTimeUserData, createNewActivity } = useAuth()

  const [showDialog, setShowDialog] = useState(false);

  const handleCloseDialog = () => setShowDialog(false);
  const handleShowDialog = () => setShowDialog(true);

  const [clickedLessonData, setClickedLessonData] = useState("")
  const [clickedSubjectData, setClickedSubjectData] = useState("")
  const [isChecked, setIsChecked] = useState("")

  const checkSubject = async () => {
    if (clickedLessonData && clickedSubjectData) {
      const codingKey = `${clickedSubjectData.grade}-${clickedLessonData.codingKey}-${clickedSubjectData.codingKey}`

      const studentRef = doc(db, "students", realTimeUserData.uid);

      if (isChecked) {
        const copyOfSubjectTrackingMap = realTimeUserData.subjectTracking
        // reset kodu
        delete copyOfSubjectTrackingMap[codingKey]

        await updateDoc(studentRef, {
          subjectTracking: {
            ...copyOfSubjectTrackingMap
          }
        });
      } else {
        await updateDoc(studentRef, {
          subjectTracking: {
            ...realTimeUserData.subjectTracking,
            [codingKey]: true
          }
        });

        await createNewActivity(`${clickedLessonData.title} dersinin ${clickedSubjectData.title} konusunu tamamladı.`)
      }

    }
  }

  return (
    <>
      <Navbar style={{ marginBottom: "24px" }} expand="lg" className="bg-white border-bottom">
        <Container>
          <Navbar.Brand>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {/* tıklanamayan */}
              <p style={{ margin: 0, color: "#000000A6", fontSize: "0.9rem", textDecoration: "none", cursor: "default" }}>Aktiviteler</p>

              {/* çizgi */}
              <p style={{ margin: "0 6px", color: "#000000A6", fontSize: "0.9rem" }} >/</p>

              <p style={{ margin: 0, color: "#000000", fontSize: "0.9rem", textDecoration: "none", cursor: "default", fontWeight: "500" }}>Konu Takibi</p>

              {/* tıklanabilir */}
              {/* <a href='/kurum-paneli/ogrenci-listesi/ogrenci-ekle' style={{ margin: 0, fontSize: "1rem", fontWeight: "500", color: "#000000", textDecoration: "none" }} >Öğrenci Ekle</a> */}
            </div>
          </Navbar.Brand>
        </Container>
      </Navbar>
      {
        (realTimeUserData) &&
        <Container style={{ marginBottom: "24px" }}>
          <Container style={{ display: "flex", justifyContent: "center", alignItems: "flex-start" }}>
            <Row style={{ width: "100%", display: "flex", justifyContent: "center" }}>
              <Col lg={3} md={2} sm={1} />
              <Col lg={6} md={8} sm={10} style={{ padding: "0", maxWidth: "450px" }}>
                <Accordion alwaysOpen>
                  {
                    ["9", "10", "11", "12"].map((grade, index) => {

                      let numberOfSubjects = 0

                      subjects.forEach((subject) => {
                        if (subject.grade === grade) {
                          numberOfSubjects += 1
                        }
                      })

                      let checkedSubjects = 0

                      lessons.forEach((lesson) => {
                        subjects.forEach((subject) => {
                          if (subject.lessonId === lesson.uid && subject.grade === grade) {
                            const codingKey = `${subject.grade}-${lesson.codingKey}-${subject.codingKey}`

                            if (realTimeUserData.subjectTracking) {
                              if (realTimeUserData.subjectTracking[codingKey]) {
                                checkedSubjects += 1
                              }
                            }
                          }
                        })
                      })

                      const persentageOfSubjectCompletion = Math.round((checkedSubjects / numberOfSubjects) * 100)


                      return (
                        <Accordion.Item key={index} eventKey={index + (Number(grade) * 100)}>
                          <Accordion.Header>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                              <p style={{ padding: 0, margin: 0 }}>{grade}. Sınıf</p>
                              <p style={{ padding: 0, margin: "0 8px 0 0", }}>(%{persentageOfSubjectCompletion || 0})</p>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body style={{ backgroundColor: "#F8F9FA" }}>
                            {
                              lessons && lessons.map((lesson, index) => {
                                if (lesson.grades.includes(grade)) {

                                  let numberOfSubjects = 0

                                  subjects.forEach((subject) => {
                                    if (subject.lessonId === lesson.uid && subject.grade === grade) {
                                      numberOfSubjects += 1
                                    }
                                  })

                                  let checkedSubjects = 0

                                  subjects.forEach((subject) => {
                                    if (subject.lessonId === lesson.uid && subject.grade === grade) {
                                      const codingKey = `${subject.grade}-${lesson.codingKey}-${subject.codingKey}`

                                      if (realTimeUserData.subjectTracking) {
                                        if (realTimeUserData.subjectTracking[codingKey]) {
                                          checkedSubjects += 1
                                        }
                                      }
                                    }
                                  })

                                  const persentageOfSubjectCompletion = Math.round((checkedSubjects / numberOfSubjects) * 100)

                                  return (
                                    <Accordion.Item key={index} eventKey={index + (Number(grade) * 200)}>
                                      <Accordion.Header>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                                          <p style={{ padding: 0, margin: 0 }}>{lesson.title}</p>
                                          <p style={{ padding: 0, margin: "0 8px 0 0", }}>(%{persentageOfSubjectCompletion || 0})</p>
                                        </div>
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        <Container style={{ display: "flex", flexDirection: "column", gap: "16px 0" }}>
                                          {
                                            subjects && subjects.map((subject, index) => {
                                              if (subject.grade === grade && subject.lessonId === lesson.uid) {
                                                const codingKey = `${subject.grade}-${lesson.codingKey}-${subject.codingKey}`

                                                return (
                                                  <Row key={index}>
                                                    <div style={{ backgroundColor: "#fff", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", border: "1px solid #dee2e6", padding: "12px 16px", borderRadius: "0.375rem" }}>
                                                      <p style={{ margin: 0, fontSize: "1rem", padding: 0 }}>{subject.title}</p>
                                                      <Form style={{ display: "flex", alignItems: "center", marginLeft: "16px" }}>
                                                        <Form.Check.Input readOnly checked={(realTimeUserData.subjectTracking && realTimeUserData.subjectTracking[codingKey]) || false} onClick={(e) => {
                                                          e.preventDefault()
                                                          setClickedLessonData(lesson)
                                                          setClickedSubjectData(subject)
                                                          setIsChecked(realTimeUserData.subjectTracking && realTimeUserData.subjectTracking[codingKey])
                                                          handleShowDialog()
                                                        }} style={{ borderWidth: "1.5px", width: "19px", height: "19px", margin: 0 }} />
                                                      </Form>
                                                    </div>
                                                  </Row>
                                                )
                                              } else {
                                                return null
                                              }
                                            })
                                          }
                                        </Container>

                                      </Accordion.Body>
                                    </Accordion.Item>
                                  )
                                } else {
                                  return null
                                }
                              })
                            }
                          </Accordion.Body>
                        </Accordion.Item>
                      )
                    })
                  }
                </Accordion>
              </Col>
              <Col lg={3} md={2} sm={1} />
            </Row>
          </Container>

          <Modal
            show={showDialog}
            onHide={handleCloseDialog}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
              {
                isChecked
                  ?
                  <p style={{ padding: 0, margin: 0, flex: 1 }}><span style={{ fontWeight: "500" }}>{(clickedSubjectData && clickedLessonData) && `${clickedSubjectData.title}`}</span> konusunu tamamlamıştınız. İşleme devam ederseniz tamamlanmamış gözükecek. Devam etmek istediğinize emin misiniz?</p>
                  :
                  <p style={{ padding: 0, margin: 0 }}><span style={{ fontWeight: "500" }}>{(clickedSubjectData && clickedLessonData) && `${clickedSubjectData.title}`}</span>  konusunu tamamladığınıza emin misiniz?</p>
              }
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => {
                checkSubject()
                handleCloseDialog()
              }} variant={isChecked ? "danger" : "primary"}>
                {
                  isChecked
                    ? "Kaldır"
                    : "Tamamla"
                }
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
      }
    </>
  )
}
