import React, { useEffect, useState } from 'react'
import { Navbar, Container, Table, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../firebaseConfig';

export default function TeacherList() {

  const navigate = useNavigate();

  const [teachers, setTeachers] = useState([])

  const { userData } = useAuth();

  useEffect(() => {
    if (userData) {
      const getTeachers = async () => {
        const teachers = [];
        const querySnapshot = await getDocs(query(collection(db, "teachers"), where("organizationId", "==", userData.uid)));
        querySnapshot.forEach((doc) => {
          teachers.push(doc.data())
        });
        teachers.sort((x, y) => {
          return y.createdAt - x.createdAt
        })
        setTeachers(teachers)
      }

      getTeachers()
    }
  }, [userData])

  const [turkish, setTurkish] = useState(0)
  const [social, setSocial] = useState(0)
  const [math, setMath] = useState(0)
  const [science, setScience] = useState(0)
  const [lang, setLang] = useState(0)
  const [coach, setCoach] = useState(0)

  useEffect(() => {
    if (teachers) {
      teachers.forEach((teacher) => {
        teacher.branches.forEach((branch) => {
          switch (branch) {
            case "Türkçe":
              setTurkish((prev) => prev + 1)
              break;
            case "Edebiyat":
              setTurkish((prev) => prev + 1)
              break;
            case "Tarih":
              setSocial((prev) => prev + 1)
              break;
            case "Coğrafya":
              setSocial((prev) => prev + 1)
              break;
            case "Felsefe":
              setSocial((prev) => prev + 1)
              break;
            case "Din Kültürü ve Ahlak Bilgisi":
              setSocial((prev) => prev + 1)
              break;
            case "Matematik":
              setMath((prev) => prev + 1)
              break;
            case "Geometri":
              setMath((prev) => prev + 1)
              break;
            case "Fizik":
              setScience((prev) => prev + 1)
              break;
            case "Kimya":
              setScience((prev) => prev + 1)
              break;
            case "Biyoloji":
              setScience((prev) => prev + 1)
              break;
            case "İngilizce":
              setLang((prev) => prev + 1)
              break;
            case "Almanca":
              setLang((prev) => prev + 1)
              break;
            case "Fransızca":
              setLang((prev) => prev + 1)
              break;
            case "Arapça":
              setLang((prev) => prev + 1)
              break;
            case "Rusça":
              setLang((prev) => prev + 1)
              break;
            case "Rehberlik":
              setCoach((prev) => prev + 1)
              break;
            default:
              break;
          }
        })
      })
    }
  }, [teachers])

  return (
    <>
      <Navbar style={{ marginBottom: "24px" }} expand="lg" className="bg-white border-bottom">
        <Container>
          <Navbar.Brand>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {/* tıklanamayan */}
              <p style={{ margin: 0, color: "#000000A6", fontSize: "0.9rem", textDecoration: "none", cursor: "default" }}>Öğretmenler</p>

              {/* çizgi */}
              <p style={{ margin: "0 6px", color: "#000000A6", fontSize: "0.9rem" }} >/</p>

              <p style={{ margin: 0, color: "#000000", fontSize: "0.9rem", textDecoration: "none", cursor: "default", fontWeight: "500" }}>Öğretmen Listesi</p>

              {/* tıklanabilir */}
              {/* <a href='/kurum-paneli/ogrenci-listesi/ogrenci-ekle' style={{ margin: 0, fontSize: "1rem", fontWeight: "500", color: "#000000", textDecoration: "none" }} >Öğrenci Ekle</a> */}
            </div>
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Container style={{ marginBottom: "24px" }}>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th style={{ width: "12.5%" }}>Toplam</th>
              <th style={{ width: "12.5%" }}>Türkçe</th>
              <th style={{ width: "12.5%" }}>Sosyal</th>
              <th style={{ width: "12.5%" }}>Matematik</th>
              <th style={{ width: "12.5%" }}>Fen</th>
              <th style={{ width: "12.5%" }}>Dil</th>
              <th style={{ width: "12.5%" }}>Rehber</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr style={{ verticalAlign: "middle" }}>
              <td>{teachers ? teachers.length : 0}</td>
              <td>{turkish}</td>
              <td>{social}</td>
              <td>{math}</td>
              <td>{science}</td>
              <td>{lang}</td>
              <td>{coach}</td>
              <td style={{ textAlign: "center" }}>
                <Button onClick={() => navigate("ogretmen-ekle")} size="sm">Öğretmen Ekle</Button>
              </td>
            </tr>

          </tbody>
        </Table >

        {
          teachers
            ? teachers.length > 0
              ? <Table style={{ marginTop: '8px' }} striped bordered responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th style={{ width: "13.78%" }}>Ad</th>
                    <th style={{ width: "13.78%" }}>Soyad</th>
                    <th style={{ width: "13.78%" }}>Branş</th>
                    <th style={{ width: "13.78%" }}>Öğrencileri</th>
                    <th style={{ width: "13.78%" }}>Kullanıcı Adı</th>
                    <th style={{ width: "15%" }}>Parola</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    teachers.map((teacher, index) => {
                      let branchesText = "";

                      teacher.branches.forEach((branches, index) => {
                        teacher.branches[index + 1] ? branchesText += `${branches}, ` : branchesText += `${branches}`

                      })

                      return (
                        <tr key={index} style={{ verticalAlign: "middle" }}>
                          <td>{index + 1}</td>
                          <td>{teacher.name}</td>
                          <td>{teacher.surname}</td>
                          <td>{branchesText}</td>
                          <td>{teacher.students ? teacher.students.length : 0}</td>
                          <td>{teacher.username}</td>
                          <td>{teacher.password}</td>
                          <td style={{ textAlign: "center" }}>
                            <Button onClick={() => navigate("ogretmen-duzenle/" + teacher.uid)} size="sm">Düzenle</Button>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </Table>
              : null
            : null

        }


      </Container>
    </>
  )
}
